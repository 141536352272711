import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, Avatar,Container,Button,CircularProgress,Snackbar,Alert,AppBar,Toolbar,IconButton,useMediaQuery,useTheme   } from '@mui/material';
import ares from '../img/ares.png';
import zeus from '../img/zeus.png';
import poseidon from '../img/poseidon.png';
import Cookies from 'js-cookie';
import Tutorial from '../component/Tutorial';
import translatedata from '../translate.json';
import ChatComponent from '../component/chat';
import { useCookies } from 'react-cookie';
import Chat from '../media/chat.gif';
import axios from 'axios';
const CharacterCard = ({ character,setLoginOpen,isLoggedIn,aresfee,poseidonfee,zeusfee,selectedLanguage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hovered, setHovered] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if(isLoggedIn==false){
      setLoginOpen(true)
    }else{
      navigate(`/${character.name}`);
    }
 
  };


  return (
    
    <Card 
      sx={{ 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        backgroundColor: "#1a1a1c",
        flexDirection: 'column', 
        position: 'relative',
        '&:hover': { 
          transform: 'scale(1.02)', 
          zIndex: 1, 
        } 
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
          {isLoggedIn&&
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          right: 0, 
          backgroundColor: "#1a1a1c",
          color: '#ece4d4', 
          padding: '4px 4px', 
          borderTopRightRadius: '4px', 
          borderBottomLeftRadius: '4px',
          zIndex: 2 
        }}
      >
        {character.name=="Ares"&&aresfee+"%"}
        {character.name=="Poseidon"&&poseidonfee+"%"}
        {character.name=="Zeus"&&zeusfee+"%"}
      </Box>}
  
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          backgroundColor: "#1a1a1c",
          color: '#ece4d4', 
          padding: '4px 4px', 
          borderTopLeftRadius: '4px', 
          borderBottomRightRadius: '4px',
          backgroundColor:"#d79f09"
        }}
      >
        {character.name}
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Avatar
          alt={character.name}
          src={character.img}
          sx={{ width: isMobile?150:300, height:isMobile?150: 300, marginBottom: 4 }}
        />
        <Typography variant="h6" align="center" gutterBottom sx={{color:"#ece4d4"}}>
          {character.name}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" sx={{color:"#ece4d4"}}>
          {character.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CharacterGrid = ({setLoginOpen,isLoggedIn,aresfee,poseidonfee,zeusfee,selectedLanguage,setChatOpen,messages,mailverify}) => {
  const [tutorialid,setTutorialid]=useState(0);
  const [tutorialopen,setTutorialOpen]=useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [stat, setStat] = useState(false);
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  const characters = [

    {
      name: "Poseidon",
      description: translatedata[selectedLanguage].maincard.poseidonpart1+(poseidonfee>0?poseidonfee+translatedata[selectedLanguage].maincard.poseidonpart2:40+translatedata[selectedLanguage].maincard.poseidonpart2),
      discount: '%40',
      img: poseidon
    },
    {
      name: "Zeus",
      description:translatedata[selectedLanguage].maincard.zeusdesc,
      discount: '%20',
      img: zeus
    }
  ];
  const sendmail = () => {
    setLoading(true);
    const accessToken = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    axios.post("https://api.youbetshop.com/api/user/verifymail", "", config)
      .then(response => {
        if (response.data.success=true) {
          setLoading(false);
          setMessage("Mail Gönderildi.");
          setOpen(true);
          setStat("success");
        } else {
          setLoading(false);
          setMessage(response.data.message);
          setOpen(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error creating coupon:', error);
        setMessage(error);
        setOpen(true);
        setStat("error");
      });
  }
  useEffect(() => {
    if(isLoggedIn){
      const loginfirst=Cookies.get('loginfirst');
      const tutorial=Cookies.get('tutorial');
      console.log(loginfirst)
      if(loginfirst){
        if(!tutorial=="2" && !loginfirst=="2" ){
          setTutorialOpen(true)
          setTutorialid(17)
        }
      }else{
        setTutorialOpen(true)
        setTutorialid(17)
      }
    }
    }, [isLoggedIn])
  return (
    <>      {isLoggedIn&&
      <AppBar position="static">
        <Toolbar>
          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <IconButton edge="start" color="inherit" aria-label="back" >
            <Typography variant="h6">
              {translatedata[selectedLanguage].starrace.home}
            </Typography>
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => setChatOpen(true)}>
            <img src={Chat} style={{width:"25px"}}/>
            <Typography variant="h6">
            {"Chat"}
            </Typography>
          </IconButton>
          {!isMobile &&
          <Typography variant="body1" style={{ marginRight: '10px' }}>
           
          </Typography>
        }
          </Box>
        </Toolbar>
      </AppBar>}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:"15px" }}>
            <Grid container sx={{ maxHeight: "150rem", maxWidth: "100rem", backgroundColor: "#ece4d4", borderRadius: "1rem" }}>
            {!mailverify && isLoggedIn ?
            <Grid item xs={12} md={12} sx={{ padding: "50px" }}>
              <Typography variant={"h4"}>{translatedata[selectedLanguage].registerdialog.mailcheck}</Typography>
              <Typography>{translatedata[selectedLanguage].registerdialog.mailchecktext}</Typography>
              <Box justifyContent={"flex-end"} sx={{display:"flex",marginBottom:"20px"}}>
              <Button onClick={sendmail} variant="outlined" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : translatedata[selectedLanguage].registerdialog.remail}
          </Button>
          </Box>
            </Grid>
            :

        characters.map((character, index) => (
          <Grid item xs={12} md={6} sx={{ padding: "16px" }} key={index} >
            <CharacterCard character={character} setLoginOpen={setLoginOpen} isLoggedIn={isLoggedIn} aresfee={aresfee} poseidonfee={poseidonfee}  zeusfee={zeusfee} />
          </Grid>
        ))
      }</Grid>
      </Box>
         <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
         <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}

        </Alert>
      </Snackbar>
         </>
  );
};

export default CharacterGrid;
