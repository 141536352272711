import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Typography, Grid, useTheme, InputAdornment, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import StarIcon from '@mui/icons-material/Star';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';
import chestclose from '../media/chest/chestf.png';
import chestopen from '../media/chest/chestfopen.png';
import chestopening from '../media/chest/chest-unscreen.gif';
import CaseModal from '../component/casemodal';
import Qusetion from '../media/question.gif';
import Chat from '../media/chat.gif';
const Starrace = ({ isLoggedIn, selectedLanguage,setChatOpen,poseidonfee,zeusfee}) => {
  const [confirm, setConfirm] = useState();
  const [stardetail, setStarDetail] = useState(); // info, success, error
  const [stars, setStars] = useState();
  const [affstars, setAffStars] = useState();
  const [starsconf, setStarsConf] = useState([]);
  const [affiusers, setAffiUsers] = useState([]);
  const [promocode, setPromoCode] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [affiusername, setAffiusername] = useState();
  const [promo_code, setPromo] = useState();
  const [cases, setCases] = useState([]);
  const query = new URLSearchParams(location.search);
  const hash = query.get('hash');
  const reqid = query.get('reqid');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [tutorialid, setTutorialid] = useState(4);
  const [tutorialopen, setTutorialOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['serviceToken']);

  const [openmodal, setOpenModal] = useState(false);
  const [starcount, setStarCount] = useState(0);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const recheck = () => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/api/user/mystarrace", "", config)
      .then(response => {
        setStarDetail(JSON.parse(response.data.data.star_detail))
        setStars(response.data.data.stars);
        setAffStars(response.data.data.affi_stars);
        setStarsConf(response.data.data.star_conf);
        setAffiUsers(response.data.data.affi_users)
        setPromoCode(response.data.data.promo_code)
        setAffiusername(response.data.data.affiusername)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
    axios.get("https://api.youbetshop.com/api/star-case/getstarcaseuser", config)
      .then(response => {
        setCases(response.data.data)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }
  useEffect(() => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/api/user/mystarrace", "", config)
      .then(response => {
        setStarDetail(JSON.parse(response.data.data.star_detail))
        setStars(response.data.data.stars);
        setAffStars(response.data.data.affi_stars);
        setStarsConf(response.data.data.star_conf);
        setAffiUsers(response.data.data.affi_users)
        setPromoCode(response.data.data.promo_code)
        setAffiusername(response.data.data.affiusername)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });

    axios.get("https://api.youbetshop.com/api/star-case/getstarcaseuser", config)
      .then(response => {
        setCases(response.data.data)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }, [isLoggedIn])
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const savePromocode = () => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = { promo_code: promo_code }
    axios.post("https://api.youbetshop.com/api/user/updatepromocode", param, config)
      .then(response => {
        if (response.data.success == true) {
          setMessage(response.data.message);
          setStat('success');
          setOpenSnack(true);
          recheck();
        } else {
          setMessage(response.data.message);
          setStat('error');
          setOpenSnack(true);
        }
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }
  useEffect(() => {
    if (isLoggedIn) {
      console.log("User is logged in.");
      const tutorial = Cookies.get('tutorial');
      console.log("Tutorial cookie:", tutorial);
      if (tutorial !== "2") {
        setTutorialOpen(true);
        setTutorialid(6);
      }
    }
  }, [isLoggedIn]);

  
  const maskEmail = (email) => {
    const atIndex = email.indexOf('@');

    const localPart = email.slice(0, atIndex); // @ işaretinden önceki kısım
    const domainPart = email.slice(atIndex); // @ işaretinden sonraki kısım

    if (localPart.length < 6) return email; // Eğer yerel kısım 6 karakterden kısaysa, değiştirmeden döndür

    const firstThree = localPart.slice(0, 2);
    const lastThree = localPart.slice(-2);
    const masked = `${firstThree}***${lastThree}`;

    return masked + domainPart;
  };
  // Mevcut domaini kullanarak site URL'sini oluşturma
  const siteUrl = `${window.location.origin}?promocode=${promocode}`;

  const opentutorial = (id) => {
    setTutorialOpen(true);
    setTutorialid(id);
  }
  function CaseItem({ item }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(item.opened_date));
    const [caseimg, setCaseimg] = useState(chestopen);
    const opencase = (status, id, count) => {
      if (status == 0) {
        setMessage(translatedata[selectedLanguage].starrace.plasewaitcase);
        setStat('error');
        setOpenSnack(true);
      } else {
        console.log(count)
        setCaseimg(chestopening)
        setTimeout(() => {
          setCaseimg(chestclose)
          const token = cookies.serviceToken;
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          let param = { case_id: id }
          axios.post("https://api.youbetshop.com/api/star-case/openstarcase", param, config)
            .then(response => {
              if (response.data.success == true) {
                setMessage(translatedata[selectedLanguage].starrace.casewin + count + translatedata[selectedLanguage].starrace.casewin2);
                setStat('success');
                setOpenSnack(true);
                setOpenModal(true)
                setStarCount(count)
                recheck();
              } else {
                setMessage(response.data.message);
                setStat('error');
                setOpenSnack(true);
              }
            })
            .catch(error => {
              console.error('Hata', error);
              setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
              setStat('error');
              setOpenSnack(true);
            });
        }, 2000);
      }

    }
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(item.opened_date));
      }, 1000);

      return () => clearInterval(timer);
    }, [item.opened_date]);

    function calculateTimeLeft(targetDate) {
      // targetDate'i UTC olarak alıyoruz ve yerel zaman dilimine çeviriyoruz
      const targetUTC = new Date(targetDate);
      const targetLocal = new Date(targetUTC.getTime() + targetUTC.getTimezoneOffset() * 60000);

      const now = new Date(); // Şu anki zamanı alıyoruz

      const difference = targetLocal.getTime() - now.getTime(); // Zaman farkını milisaniye cinsinden alıyoruz
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    }

    const formatTime = (value) => (value < 10 ? `0${value}` : value);

    // Kalan zamanı formatla
    const countdown = timeLeft.days !== undefined
      ? ` ${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`
      : "Kasa Açık";
    return (

      <div>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img
              src={item.openedstatus === 1 ? caseimg : chestclose}
              alt={item.case_name}
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => opencase(item.openedstatus, item.id, item.case_star_count)}
            /></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography sx={{ textAlign: "center" }}>{item.case_name}</Typography>
          </Grid>
          {item.openedstatus == 0 ?
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography sx={{ textAlign: "center", fontWeight: "600", color: "#c30000" }}>{countdown}</Typography>
            </Grid>
            :
            <Button variant="contained" color={"success"} sx={{ textAlign: "center" }} onClick={() => opencase(item.openedstatus, item.id, item.case_star_count)}>Kasayı Aç</Button>

          }
        </Grid>
      </div>

    );
  }
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
              {translatedata[selectedLanguage].starrace.home}
            </Typography>
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => setChatOpen(true)}>
            <img src={Chat} style={{width:"25px"}}/>
            <Typography variant="h6">
            {"Chat"}
            </Typography>
          </IconButton>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {translatedata[selectedLanguage].starrace.starrace}
          </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh'
        }}
      >
        {isLoggedIn ?

          <>
            <Grid container spacing={2} sx={{ marginTop: "30px" }}>
              <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginLeft: "15px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{justifyContent:"space-between",alignItems:"center",backgroundColor:"#2d2d2db8",marginBottom:"5px",display:"flex"}}>
                            <Typography variant="h4" sx={{color: "#d79f09" }}>
                            {translatedata[selectedLanguage].header.starrace}
                            </Typography>
                            <img src={Qusetion} style={{ maxWidth: "75px", display: "flex" }} onClick={() => opentutorial(6)} />
                            </Box>
        </Grid>
                  <Paper elevation={3} sx={{ paddingLeft: 2, paddingBottom: 2 }}>
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      justifyContent={isMobile ? 'flex-start' : 'space-between'}
                      alignItems={isMobile ? 'flex-start' : 'center'}
                    >      {isMobile &&
                      <Grid container justifyContent={"end"}>

                        <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(2)} />
                      </Grid>}
                      <Box display="flex" alignItems="center" sx={{ textAlign: "left", justifyContent: "space-between", width: isMobile ? "100%" : "20%", marginBottom: isMobile ? 2 : 0 }}>
                        <Typography>{translatedata[selectedLanguage].starrace.yourpromocode}<br /> {promocode}</Typography>
                        <IconButton onClick={() => handleCopy(promocode)} sx={{ marginLeft: 1 }}>
                          <FileCopyIcon />
                        </IconButton>
                      </Box>
                      <Box display="flex" alignItems="center" sx={{ textAlign: isMobile ? 'left' : 'right', justifyContent: "space-between", width: isMobile ? "100%" : "40%" }}>
                        <Typography>
                          {translatedata[selectedLanguage].starrace.yourpromolink}<br />
                          <a href={siteUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1976d2', marginLeft: 5 }}>
                            {siteUrl}
                          </a>
                        </Typography>
                        <IconButton onClick={() => handleCopy(siteUrl)} sx={{ marginLeft: 1 }}>
                          <FileCopyIcon />
                        </IconButton>
                        {!isMobile &&
                          <img src={Qusetion} style={{ maxWidth: "80px", display: "flex" }} onClick={() => opentutorial(2)} />
                        }

                      </Box>

                    </Box>

                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>

                <Paper elevation={3} sx={{ padding: 1, width: '100%', marginBottom: "10px" }}>
                <Grid container justifyContent={"end"}>

<img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(1)} />
</Grid>
                  <Box textAlign="center" marginBottom={2}>
                    <Typography variant="h4" sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.starcase}
                    </Typography>
                    <Typography sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.starcasetext}
                    </Typography>
                  </Box>
                  <Grid container>
                    {cases.map((item, index) => (
                      <Grid item key={index} xs={4} sm={4} md={4} lg={4}>
                        <CaseItem item={item} />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%' }}>
                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(4)} />
                    </Grid>}

                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starpoint}
                      </Typography>
                      <Typography sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracetext2}
                      </Typography>
                    </Box>
                    {!isMobile &&
                      <img src={Qusetion} style={{ maxWidth: "50px",height:"50px", display: "flex" }} onClick={() => opentutorial(4)} />
                    }
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}></TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.star}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.affistar}</TableCell>
                          <TableCell>{affstars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.totalstar}</TableCell>
                          <TableCell>{stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.poseidonstarcount}</TableCell>
                          <TableCell>{translatedata[selectedLanguage].starrace.totaldiscount} {starsconf.zeus_value*stars}%| {translatedata[selectedLanguage].starrace.nowposeidon} {poseidonfee}%</TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.zeusstarcount}</TableCell>
                          <TableCell>{translatedata[selectedLanguage].starrace.totaldiscount} {starsconf.zeus_value*stars}%| {translatedata[selectedLanguage].starrace.nowzeus} {zeusfee}%</TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.starmoney}</TableCell>
                          <TableCell><Box sx={{display:"flex",alignItems:"center" }}>{(starsconf.star_money_value*stars).toFixed(2)} TL {"("+starsconf.star_money_value+" TL)"}<img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(12)} /></Box>  </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%' }}>

                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(3)} />
                    </Grid>}
                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracemission}
                      </Typography>
                      <Typography sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracetext}
                      </Typography>
                    </Box>
                    {!isMobile &&
                      <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(3)} />
                    }
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{alignItems:"center"}}>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555'}}><Box sx={{display:"flex",alignItems:"center" }}>{translatedata[selectedLanguage].starrace.missionname}  <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(0)} /></Box></TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555'}}>{translatedata[selectedLanguage].starrace.missionstatus}</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555',display:"flex",alignItems:"center" }}><Box sx={{display:"flex",alignItems:"center" }}>{translatedata[selectedLanguage].starrace.starcount}<img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(11)} /></Box></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stardetail?.map((item, index) => (
                          <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
                            <TableCell>{item.taskname}</TableCell>
                            <TableCell sx={{ color: item.status == "1" ? 'green' : 'inherit', fontWeight: 'bold' }}>
                              {item.status == "1" ? '✓' : translatedata[selectedLanguage].starrace.waiting}
                            </TableCell>
                            <TableCell sx={{padding:"10px"}}>
                              {item.value} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%' }}>
                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <img src={Qusetion} style={{ maxWidth: "50px", display: "flex" }} onClick={() => opentutorial(5)} />
                    </Grid>}
                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                  <Box>
                    {affiusername ?


                      <Typography sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].starrace.youraffi}<br /> {affiusername}</Typography>

                      :
                      <Box display="flex" alignItems="center" sx={{ textAlign: "center", marginBottom: isMobile ? 2 : 0 }}>
                        <TextField
                          margin="dense"
                          label={translatedata[selectedLanguage].starrace.promocodewrite}
                          type={'text'}
                          fullWidth
                          value={promo_code}
                          onChange={(e) => setPromo(e.target.value)}
                        />
                        <IconButton onClick={() => savePromocode()} sx={{ marginLeft: 1, backgroundColor: "#29963a" }}>
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    }
                    <Typography variant="h4" sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.affiliatenet}
                    </Typography>
                    <Typography sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.starracetext3}
                    </Typography>
                    </Box>
                    {!isMobile&&
              <img src={Qusetion} style={{maxWidth:"100px",display:"flex"}} onClick={()=>opentutorial(5 )}/>
          }
          
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.username}</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.starcountamo}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {affiusers?.map((user, index) => (
                          <TableRow key={user.id} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
                            <TableCell>{maskEmail(user.username)}</TableCell>
                            <TableCell>
                              {user.stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </>
          :
          <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
            <Typography sx={{ color: "#333" }}>
              {translatedata[selectedLanguage].starrace.pleaselogin}
            </Typography> </Paper>}

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
      <CaseModal openmodal={openmodal} setOpenModal={setOpenModal} starcount={starcount} selectedLanguage={selectedLanguage} />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
    </>
  );
};

export default Starrace;
