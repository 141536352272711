import React, { useState, useEffect,useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles,Drawer,Box,Button,IconButton,Typography,useTheme,useMediaQuery  } from '@mui/material';
import CharacterGrid from './component/mainpage';
import Poseidon from './pages/poseidon';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Mybets from './pages/my-bets';
import Ares from './pages/ares';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import Mailverify from './pages/mailverify';
import Transactions from './pages/transactions';
import Help from './pages/help';
import ResetPasswordForm from './pages/resetpass';
import WithdrawReq from './pages/withdrawreq';
import DepositReq from './pages/depositreq';
import ComingSoonZeus from './pages/zeus';
import Telegram from './pages/telegram';
import Register from './pages/register';
import Withdrawapprove from './pages/withdrawapprove';
import Starrace from './pages/star-race';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import Tutorial from './component/Tutorial';
import ChatComponent from './component/chatcomp';
const theme = createTheme({
  palette: {
    primary: {
      main: '#d79f09', // Replace with your desired primary color
    },
  },
});

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Giriş durumu, bu değeri değiştirerek test edebilirsiniz.
  const [username, setUsername] = useState("Zeus Doe");
  const [balance, setBalance] = useState(0); 
  const [newbalance, setNewBalance] = useState(0); 
  const [blockedbalance, setNewBlockedBalance] = useState(0); 
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [blockbalance,setBlockBalance]= useState(0);
  const [verifystatus, setVerifyStatus] = useState(false);
  const [aresfee,setAresFee]= useState(0);
  const [poseidonfee,setPoseidonFee]= useState(0);
  const [zeusfee,setZeusFee]= useState(0);
  const [notify,setNotify]= useState(0);
  const [promocodes, setPromocode] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [chatOpen, setChatOpen] = useState(false);
  const [mailverify, setMailVerify] = useState('');
  const socketRef = useRef(null);
  const [tutorialid, setTutorialid] = useState(4);
  const [tutorialopen, setTutorialOpen] = useState(false);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
      const token = Cookies.get('serviceToken');
      console.log('Token retrieved from cookie:', token);
  
      if (!token) {
          console.error('No token found. Please ensure the token is set correctly.');
          return;
      }
  
      socketRef.current = io('https://chat.youbetshop.com', {
          extraHeaders: {
              'Authorization': `Bearer ${token}`
          }
      });
  
      socketRef.current.on('connect', () => {
          console.log('Connected to server');
      });
  
      socketRef.current.on('connect_error', (err) => {
          console.error('Connection error:', err.message);
      });
  
      socketRef.current.on('error', (err) => {
          console.error('Socket error:', err);
      });
  
      socketRef.current.on('old messages', (oldMessages) => {
          console.log('Received old messages:', oldMessages);
          setMessages(oldMessages);
          setTimeout(() => scrollToBottom(), 100);  // Gecikme ekleyin
      });
  
      socketRef.current.on('chat message', (msg) => {
          console.log('Received chat message:', msg);
          setMessages(prevMessages => [...prevMessages, msg]);
          
      });
  
      return () => {
          if (socketRef.current) {
              socketRef.current.disconnect();
          }
      };
  }, [isLoggedIn]);

  useEffect(() => {
      if (chatOpen) {
          setTimeout(() => scrollToBottom(), 100);  // Gecikme ekleyin
      }
  }, [chatOpen]);


  const handleSendMessage = (e) => {
      e.preventDefault();
      if (inputValue) {
          console.log('Message sent:', inputValue);
          socketRef.current.emit('chat message', inputValue );
          setInputValue('');
          setTimeout(() =>  scrollToBottom(), 100); 
          
      }
  };
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
        
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      
    }
};


  useEffect(() => {
    // Belirli z-index değerine sahip <div> öğelerini Seçenek
    const removeSpecificDiv = () => {
      const divs = document.querySelectorAll('div');
      divs.forEach(div => {
        const style = div.getAttribute('style');
        if (style && style.includes('z-index: 100000;')) {
          div.remove();
        }
      });
    };
 
    // removeSpecificDiv fonksiyonunu 500ms'de bir çalıştır
    const intervalId = setInterval(removeSpecificDiv, 100);
  
    // Bileşen Seçenekıldığında interval'i temizle
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
useEffect(() => {
  if(cookies.lang){
    setSelectedLanguage(cookies.lang)
  }

}, [])

  useEffect(() => {
    // URLSearchParams ile URL'deki parametreleri al
    const params = new URLSearchParams(window.location.search);
    const promo = params.get('promocode');

    if (promo) {
      setPromocode(promo);
    }
  }, []);
  const recheck =()=>{
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.com/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setNewBalance(response.data.data.balance);
              setAresFee(response.data.data.ares_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setZeusFee(response.data.data.zeus_fee)
              setNewBlockedBalance(response.data.data.block_balance);
              setBlockBalance(response.data.data.block_balance);
              setMailVerify(response.data.data.email_verification);
              setVerifyStatus(response.data.data.verification_status)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (chatOpen && event.key === 'Enter') {
        handleSendMessage(event);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [chatOpen, inputValue]);
  useEffect(() => {
    if (cookies.serviceToken) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cookies.serviceToken) {
        config.headers['Authorization'] = `Bearer ${cookies.serviceToken}`;
      }

      axios.post("https://api.youbetshop.com/verify", { token: cookies.serviceToken }, config)
        .then(response => {
          if (response) {
            if (response.data.success === true) {
              setIsLoggedIn(true);
              setBalance(response.data.data.balance);
              setBlockBalance(response.data.data.block_balance);
              setNewBlockedBalance(response.data.data.block_balance);
              setNewBalance(response.data.data.balance);
              setVerifyStatus(response.data.data.verification_status);
              setAresFee(response.data.data.ares_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setPoseidonFee(response.data.data.poseidon_fee)
              setMailVerify(response.data.data.email_verification)
              setZeusFee(response.data.data.zeus_fee)
              setNotify(response.data.data.ticket_admin_res)
              setUsername(cookies.email);
              setIsLoggedIn(true);
            } else {
              removeCookie("email");
              removeCookie("serviceToken");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          removeCookie("email");
          removeCookie("serviceToken");
        });
    }else{
      removeCookie("email");
              removeCookie("serviceToken");
    }
  }, [cookies, removeCookie]);
  const maskEmail = (email) => {
    const atIndex = email.indexOf('@');

    const localPart = email.slice(0, atIndex); // @ işaretinden önceki kısım
    const domainPart = email.slice(atIndex); // @ işaretinden sonraki kısım

    if (localPart.length < 6) return email; // Eğer yerel kısım 6 karakterden kısaysa, değiştirmeden döndür

    const firstThree = localPart.slice(0, 2);
    const lastThree = localPart.slice(-2);
    const masked = `${firstThree}***${lastThree}`;

    return masked + domainPart;
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundImage: 'url(/image/bg.webp)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          },
        }}
      />
     
      <Router>
        <Header isLoggedIn={isLoggedIn} notify={notify} setSelectedLanguage={setSelectedLanguage} tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} setNotify={setNotify} promocodes={promocodes} isLoginOpen={isLoginOpen} setLoginOpen={setLoginOpen} balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} username={username} />
        <Routes>
          <Route path="/" element={<CharacterGrid setLoginOpen={setLoginOpen} mailverify={mailverify} messages={messages} setChatOpen={setChatOpen}  selectedLanguage={selectedLanguage} isLoggedIn={isLoggedIn} aresfee={aresfee} poseidonfee={poseidonfee}  zeusfee={zeusfee} />} />
          <Route path="/poseidon" element={<Poseidon recheck={recheck} newbalance={newbalance} messages={messages} setChatOpen={setChatOpen} isLoggedIn={isLoggedIn} selectedLanguage={selectedLanguage} blockedbalance={blockedbalance}  poseidonfee={poseidonfee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/ares" element={<Ares recheck={recheck} setChatOpen={setChatOpen} newbalance={newbalance} selectedLanguage={selectedLanguage} blockedbalance={blockedbalance} aresfee={aresfee}  balance={balance} setBlockBalance={setBlockBalance} setBalance={setBalance} blockbalance={blockbalance} />} />
          <Route path="/my-bets" element={<Mybets selectedLanguage={selectedLanguage} setChatOpen={setChatOpen}/>} />
          <Route path="/zeus" element={<ComingSoonZeus selectedLanguage={selectedLanguage} />} />
          <Route path="/verify-mail" element={<Mailverify selectedLanguage={selectedLanguage} setChatOpen={setChatOpen}/>} />
          <Route path="/deposit" element={<Deposit recheck={recheck} isLoggedIn={isLoggedIn} setChatOpen={setChatOpen}  selectedLanguage={selectedLanguage}/>} />
          <Route path="/withdraw" element={<Withdraw recheck={recheck} verifystatus={verifystatus} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/transactions" element={<Transactions recheck={recheck} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/help" element={<Help recheck={recheck} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/reset-password" element={<ResetPasswordForm recheck={recheck} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/withdrawreq" element={<WithdrawReq recheck={recheck} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/depositreq" element={<DepositReq recheck={recheck} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
          <Route path="/telegram" element={<Telegram selectedLanguage={selectedLanguage} setChatOpen={setChatOpen}/>} />
          <Route path="/star-race" element={<Starrace isLoggedIn={isLoggedIn} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage} poseidonfee={poseidonfee}  zeusfee={zeusfee} />} />
          <Route path="/approve-withdraw" element={<Withdrawapprove isLoggedIn={isLoggedIn} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage} />} />
          <Route path="/register" element={<Register isLoggedIn={isLoggedIn} setLoginOpen={setLoginOpen} setChatOpen={setChatOpen} selectedLanguage={selectedLanguage}/>} />
        </Routes>
      </Router>
      <Drawer
                anchor="top"
                open={chatOpen}
                onClose={()=>{setChatOpen(false)}}
                PaperProps={{
                    sx: { height: '600px', padding: '10px' },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Chat</Typography>
                    <IconButton onClick={()=>{setChatOpen(false)}}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box 
                    ref={messagesContainerRef}
                    sx={{ marginTop: '20px', overflowY: 'scroll', height: 'calc(100% - 100px)' }}
                >
                    {messages?.map((message, index) => (
                        <Typography key={index} variant="body2" sx={{ marginBottom: '10px' }}>
                            <span style={{ color: message?.color || 'black' }}>
                                {maskEmail(message?.username)}
                            </span>: {typeof message?.message === 'object' ? JSON.stringify(message?.message) : message?.message}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', marginTop: 'auto' }}>
                    <input
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Mesajınızı yazın"
                        style={{ padding: '10px', width: '90%', marginRight: '0.5%' }}
                    />
                    <Button variant="contained" onClick={handleSendMessage} style={{ padding: '10px', width: '9%' }}>
                        Gönder
                    </Button>
                </Box>
            </Drawer>
            <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
            {cookies.serviceToken &&
            <ChatComponent token={cookies.serviceToken}/>
            }
            
    </ThemeProvider>
  );
};

export default App;
