import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Badge, Box, Typography, TextField, Button, Divider } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import io from 'socket.io-client';
import notificationSound from './notification.mp3'; // Ses dosyasını buraya ekleyin

const SOCKET_SERVER_URL = 'https://helpchat.youbetshop.com';

const ChatComponent = ({ token }) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null); // Ses dosyası referansı

  // Scroll'u her mesaj geldiğinde en alta kaydırma fonksiyonu
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Sadece ilk render sırasında socket bağlantısı kurulur
  useEffect(() => {
    const newSocket = io(SOCKET_SERVER_URL, {
      auth: {
        token: token,
        user_type: 1,
      },
    });

    setSocket(newSocket);

    // Eski mesajları yüklendiğinde
    newSocket.on('previousMessages', (loadedMessages) => {
      const messagesWithTimestamp = loadedMessages.map((msg) => ({
        ...msg,
        timestamp: msg.timestamp ? new Date(msg.timestamp) : new Date(),
      }));
      setMessages(messagesWithTimestamp);
    });

    // Yeni mesaj geldiğinde state'i güncelle
    newSocket.on('message', (message) => {
      if (message && message.text) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { ...message, timestamp: new Date() },
        ]);
        scrollToBottom();
        if (!open) {
          setUnreadCount((prevCount) => prevCount + 1);
          playNotificationSound(); // Ses çal
        }
      }
    });

    newSocket.on('connect_error', (err) => {
      console.error('Bağlantı hatası:', err.message);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [token]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChatWindow = () => {
    scrollToBottom();
    setOpen(!open);
    if (!open) {
      setUnreadCount(0);
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() && socket) {
      socket.emit('message', newMessage);
      setNewMessage('');
    }
  };

  const playNotificationSound = () => {
    const audio = audioRef.current;
    if (audio && audio.play) {
      audio.play()
        .then(() => {
          console.log('Ses başarıyla çalındı');
        })
        .catch((error) => {
          console.error('Ses çalma hatası:', error);
        });
    } else {
      console.error('Ses çalınamadı');
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = format(new Date(message.timestamp), 'dd MMM yyyy');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 5 }}>
      <IconButton
        onClick={toggleChatWindow}
        sx={{ backgroundColor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
      >
        <Badge badgeContent={unreadCount} color="secondary">
          <ChatBubbleIcon fontSize="large" />
        </Badge>
      </IconButton>

      {open && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            width: 350,
            height: 500,
            backgroundColor: '#fff',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 8,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 5,
          }}
        >
          <Box sx={{ backgroundColor: '#d79f09', color: '#fff', p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Live Help - Canlı Destek </Typography>
            <CloseIcon onClick={toggleChatWindow} sx={{ cursor: 'pointer' }} />
          </Box>

          <Box sx={{ flex: 1, p: 2, overflowY: 'auto' }}>
            {Object.keys(groupedMessages).map((date) => (
              <Box key={date} sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ color: '#1976d2', mb: 1 }}>
                  {date}
                </Typography>
                {groupedMessages[date].map((message, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: message.user_type === 1 ? '#e8ffee' : '#f0f0f0',
                      borderRadius: 2,
                      p: 1,
                      mb: 1,
                      maxWidth: '80%',
                      ml: message.user_type === 1 ? 'auto' : 0,
                      mr: message.user_type === 1 ? 0 : 'auto',
                      textAlign: message.user_type === 1 ? 'right' : 'left',
                    }}
                  >
                    <Typography variant="caption" sx={{ display: 'block', mt: 0.5, color: '#bbb', fontSize: '10px' }}>
                      {message.sender} {format(new Date(message.timestamp), 'HH:mm')}
                    </Typography>
                    <Typography variant="body2">{message.text}</Typography>
                  </Box>
                ))}
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </Box>

          <Divider />

          <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
            <TextField
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Type Message"
            />
            <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ ml: 1 }}>
              Send
            </Button>
          </Box>

        </Box>
      )}

      {/* Bildirim sesi */}
      <audio ref={audioRef} src={notificationSound} preload="auto" />
    </Box>
  );
};

export default ChatComponent;
