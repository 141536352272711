import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { Button, Box, Typography, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';

const ChatComponent = ({setChatOpen,messages}) => {
    const toggleChat = () => {
        setChatOpen(prevState => !prevState);
    };
    const maskEmail = (email) => {
        const atIndex = email.indexOf('@');
    
        const localPart = email.slice(0, atIndex); // @ işaretinden önceki kısım
        const domainPart = email.slice(atIndex); // @ işaretinden sonraki kısım
    
        if (localPart.length < 6) return email; // Eğer yerel kısım 6 karakterden kısaysa, değiştirmeden döndür
    
        const firstThree = localPart.slice(0, 2);
        const lastThree = localPart.slice(-2);
        const masked = `${firstThree}***${lastThree}`;
    
        return masked + domainPart;
      };
    return (
        <>
            <Box sx={{ marginBottom: "20px" }}>
            <Typography variant="h6">Canlı Sohbet</Typography>
                {messages?.slice(-3).map((message, index) => (
                    <Typography key={index+"s"} variant="body2">
                        <span style={{ color: message?.color || 'black' }}>{maskEmail(message?.username)}</span>:  {typeof message?.message === 'object' ? JSON.stringify(message?.message) : message?.message}
                    </Typography>
                ))}
                <Button variant="contained" color="primary" sx={{padding:"6px 6px",fontSize:"0.75rem"}} onClick={toggleChat}>
                    Chat Ekranını Aç
                </Button>
            </Box>


        </>
    );
};

export default ChatComponent;
