import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box ,useMediaQuery,useTheme} from '@mui/material';
import Confetti from 'react-confetti';
import chestopened from '../media/chest/chestl.png';
import translatedata from '../translate.json';
// Confetti bileşeninin tüm modal alanını kaplamasını sağlamak için stiller
const ConfettiWrapper = ({ children }) => (
  <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
    <Confetti
      width={window.innerWidth}
      height={window.innerHeight}
    />
    {children}
  </Box>
);

const CaseModal = ({starcount,setOpenModal,openmodal,selectedLanguage}) => {
  console.log(starcount)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => setOpenModal(false);

  return (
    
      <Dialog
        open={openmodal}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: isMobile?'90%':'20%',
            maxWidth: 'none', // Modal genişliğini %90 yapar
            overflow: 'hidden', // İçerik kaydırma (scroll) olmamasını sağlar
          },
        }}
      >
        <ConfettiWrapper>
          <Box sx={{ padding: 2, textAlign: 'center' }}>
          <img
          src={chestopened}
          alt={"win"}
          style={{ width: '50%', cursor: 'pointer' }}
        />
            <DialogContent>
            <Typography variant="h4">
               {translatedata[selectedLanguage].starrace.welcome}
              </Typography>
              <Typography variant="h6">
                {starcount+translatedata[selectedLanguage].starrace.casewin2}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{translatedata[selectedLanguage] .starrace.close}</Button>
            </DialogActions>
          </Box>
        </ConfettiWrapper>
      </Dialog>
  );
};

export default CaseModal;
