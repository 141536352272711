import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import BlockIcon from '@mui/icons-material/Block';
import translatedata from '../translate.json';
const tutorialData = {
  "tr":[{
    "title": "Yıldız Nasıl Artar?",
    "description": "Kendi hesabınızda ki yıldız görevlerini yerine getirerek, yıldız sandıklarını patlatarak veya promosyon linkinizi kullanarak yıldız sayınızı çoğaltıp yıldız avantajlarından faydalanabilirsiniz",
    "images": [
      {
        "src": "/image/image1.png"
      }
    ]
  },
  {
    "title": "Yıldız Kasaları Nedir?",
    "description": "Sayaç dolduğunda yıldız kasasına tıklayıp patlatarak yıldız kazanabilirsiniz,yıldız sayısı çoğaldıkça,Poseidon ve Zeus karakterlerinde daha avantajlı komisyonlar elde edersiniz,ayrıca her hafta Çarşamba 00:01 de yıldız sayınız kadar nakit ödül hesabınıza çevrim şartsız otomatik tanımlanır",
    "images": [
      {
        "src": "/image/image2.png"
      }
    ]
  },
  {
    "title": "Promosyon linki veya kodu ne işe yarar?",
    "description": "Sizin promosyon linkinize tıklayıp üye olan veya sizin promosyon kodunuzu üye olurken referanslar bölümüne yazan yeni kullanıcıları sizin affiliate ağınıza bağlamaya yarar.Yeni gelen üye kaç yıldız kazanırsa,Youbetshop tarafından yarısı size affiliate yıldız kazancı olarak eklenir",
    "images": [
      {
        "src": "/image/image3.png"
      }
    ]
  },
  {
    "title": "Yıldız Görevleri Nedir?",
    "description": "Sizden istenen maddeleri yerine getirdikçe yıldız kazanmanızı sağlar,görevler her hafta değişebilir. Not:Yıldız yarışından kazanılan nakit ödülün ve affiliate yıldızlarının hesabınıza yüklenmesi için her üyenin en az 4 görevi yerine getirmesi ve 10k tutarını geçecek şekilde minimum 3 kupon yapması gerekmektedir.",
    "images": [
      {
        "src": "/image/image4.png"
      }
    ]
  },
  {
    "title": "Yıldız Tablosu?",
    "description": "Bu bölümde toplam kaç yıldız kazandığınız,Poseidon ve Zeus karakterlerinde ne kadar komisyon avantajı elde ettiğiniz ve Çarşamba günü hesabınıza ne kadar yıldız nakit ödülü verileceğine dair güncel bilgileri takip edebilirsiniz",
    "images": [
      {
        "src": "/image/image5.png"
      }
    ]
  },
  {
    "title": "Affiliate Ağınız?",
    "description": "Bu bölümde sizin referans kodunuz veya referans linkiniz ile üye olan kişilerin yıldız durumunu anlık güncel olarak takip edebilirsiniz",
    "images": [
      {
        "src": "/image/image6.png"
      }
    ]
  },
  {
    "title": "Yıldız Yarışı Nedir?",
    "description": "Yıldız sayınız arttıkça vip müşteri konumuna geçersiniz ve yıldızlarınıza göre çok özel avantajlarınız olur,herhangi bir talepte bulunmanıza gerek kalmadan sistem tarafından yıldız avantajlarınız hesabınıza yüklenecektir,Her Çarşamba 00:01 de yıldız sayınıza göre hesabınıza çevrim şartsız nakit ödül verilir ve Yıldız sayınıza bağlı olarak Poseidon dan aldığınız kuponlara daha az komisyon ödersiniz ve Zeus dan olası bir kayıp yaşarsanız daha fazla discount(iade) alırsınız",
    "images": [
      {
        "src": "/image/image7.png"
      }
    ]
  },
  {
    "title": "Ticket Nedir?",
    "description": "Profesyonel ekibimiz ile doğrudan iletişim kurabileceğiniz bu bölümden ,her türlü sorunlarınıza çözüm bulabilirsiniz, talepleriniz en geç 10 ile 45 dakika arasında yoğunluk sırasına göre cevaplanacaktır",
    "images": [
      {
        "src": "/image/image8.png"
      }
    ]
  },
  {
    "title": "Neden Telegram kullanmalıyım?",
    "description": "Telegram kullanmak görseldeki bildirimleri anlık almanızı sağlar,kampanyalar ve avantajlar hakkında önceden bilgilendirilmek istiyorsanız telegram botunu aktif etmenizi tavsiye ediyoruz.Ayrıca Telegram ı aktif eden üyeler extra yıldız kazanıyor",
    "images": [
      {
        "src": "/image/image9.png"
      }
    ]
  },
  {
    "title": "Poseidon un oynadığı Kuponlar",
    "description": "Bu bölümde Poseidon karakterine oynattığınız kuponların takibini yapabilirsiniz,Poseidon un oynadığı kuponun içeriğindeki maçların hepsini yeşil renkte görüyorsanız Poseidon kazanmış demektir ve siz para kaybedersiniz, fakat kupon içeriğinde bir maç kırmızı olduysa Poseidon bilememiş ve kaybetmiş olur.Poseidon un kaybetmesi demek sizin kazanmanız demektir,burada kasa sizsiniz bu yüzden kırmızı renk olan yani yatan kuponlar aslında sizin kazancınızdır.Bu kuponları Poseidon oynadığı için onun kaybetmesi sizin kazanmış olmanız anlamına gelmektedir",
    "images": [
      {
        "src": "/image/image10.png"
      }
    ]
  },
  {
    "title": "Neden Usd-T (Tether) kullanmalıyım?",
    "description": "Size gönderilen para dünyanın neresinde olursanız olun kolay bir şekilde dakikalar içinde ulaşır ve 0 Komisyon ile yatırım ve çekimlerinizi yapabilirsiniz.Ayrıca Usd-T(Tether) kullanan üyeler extra yıldız kazanıyor.",
    "images": [
      {
        "src": "/image/image11.png"
      }
    ]
  },
  {
    "title": "Yıldız Ne Kazandırır?",
    "description": "Üyeler yıldızı ne kadar fazlaysa o kadar kolay para kazanır.Her bir yıldız sizi daha avantajlı hale getirir,Yıldız sayısına göre hak edilen avantajları sistem otomatik olarak verir herhangi bir talepte bulunmanıza gerek kalmaz.",
    "images": [
      {
        "src": "/image/image12.png"
      }
    ]
  },
  {
    "title": "Yıldız kazancı örnek:",
    "description": "Poseidon un aldığı komisyonu yıldız sayısına göre daha az ödersiniz. Örnek: 100 yıldızı olan üye %100 indirim kazanacaktır ve Poseidon a hiç komisyon ödememiş olur ve Zeus slotlarında eğer bir kayıp yaşarsanız daha fazla discount(iade) alırsınız. Bu tarz indirimleri sistem kendiliğinden hesabınıza tanımlayacaktır sizin bir talepte bulunmanıza gerek yoktur",
    "images": [
      {
        "src": "/image/image13.png"
      }
    ]
  },
  {
    "title": "Poseidon Mantığı Nedir?",
    "description": "Klasik bahis sitelerinde oynadığınız kombine kuponlar tek maçtan yatınca diğer maçları bilmiş olmanızın bir yararı olmaz. Kaybeden siz olursunuz, Kasa herzaman kazanır sözünden yola çıkarak burda Poseidon u bir oyuncu sizide kasa olmaya davet ediyoruz. Artık herşey tersine döndü; seçtiğiniz maçlardan 1 tanesini dahi bilseniz kazanan siz oluyorsunuz. Maçı siz seçersiniz, seçeneği siz belirlersiniz, oynayacağı miktarı siz belirlersiniz ve Poseidon oynar. Burda amaç Poseidon kaybetmeli, siz kazanmalısınız.",
    "images": [
      {
        "src": "/image/image14.png"
      }
    ]
  },
  {
    "title": "Poseidon Neden kaybetmeli?",
    "description": "Poseidon un oynayacağı miktarı ve seçeneği siz belirlersiniz,şartlara bağlı kalmadan kendi stratejinizi kendiniz oluşturup Poseidon un kaybetmesini sağlayabilirsiniz, Poseidon a seçtirip oynattığınız maçlardan bir tanesini dahi bilseniz yine de kazanan siz olacaksınız,yıldız sayınız arttıkça Poseidon a ödediğiniz komisyon daha cazip seviyeye gelir",
    "images": [
      {
        "src": "/image/image15.png"
      }
    ]
  },
  {
    "title": "Kasa?",
    "description": "Bu bölümde hesabınıza ne kadar para yatırdığınız ve çektiğiniz, kayıp ve kazançlarınız hakkında ki tüm hareketlerinizin dökümünü inceleyebilirsiniz eğer sormak istediğiniz bir konu olursa desteğe başvurmakdan çekinmeyiniz",
    "images": [
      {
        "src": "/image/image16.png"
      }
    ]
  },
  {
    "title": "Bloke Nedir?",
    "description": "Poseidon karakterine oynattığınız kuponun, kazanç riskinin tutulduğu bölümdür.Eğer Poseidon’a oynattığınız kupon kaybetmişse yani siz kazanırsanız ,blokeli tutar maçlar sonuçlandıktan sonra kazancınız ile birlikte ana bakiyenize iade edilir.Eğer Poseidon a oynattığınız kupon tutarsa siz kaybetmiş olursunuz ve sizin ödeyeceğiniz blokeye alınan miktar iade edilmez.Konu ile alakalı hesap dökümlerini görmek isterseniz Kasa sekmesini ziyaret edebilirsiniz",
    "images": [
      {
        "src": "/image/image17.png"
      }
    ]
  }
],
"en":[
  {
    "title": "How to Increase Stars?",
    "description": "By completing the star tasks on your account, opening star chests, or using your promotion link, you can increase your star count and benefit from star advantages.",
    "images": [
      {
        "src": "/image/image1.png"
      }
    ]
  },
  {
    "title": "What are Star Chests?",
    "description": "When the counter is full, you can click and open the star chest to earn stars. As the number of stars increases, you can obtain more favorable commissions with the Poseidon and Zeus characters. Additionally, each Wednesday at 00:01, the cash reward equivalent to your star count is automatically credited to your account without any wagering requirements.",
    "images": [
      {
        "src": "/image/image2.png"
      }
    ]
  },
  {
    "title": "What is the Purpose of the Promotion Link or Code?",
    "description": "The promotion link or code allows new users who click on your link or enter your promotion code during registration to be added to your affiliate network. Whatever stars the new user earns, Youbetshop adds half of that amount to your account as affiliate star earnings.",
    "images": [
      {
        "src": "/image/image3.png"
      }
    ]
  },
  {
    "title": "What are Star Tasks?",
    "description": "Completing the required tasks earns you stars. The tasks may change weekly. Note: To receive the cash reward from the Star Race and affiliate stars, each member must complete at least 4 tasks.",
    "images": [
      {
        "src": "/image/image4.png"
      }
    ]
  },
  {
    "title": "Star Table?",
    "description": "In this section, you can track the total number of stars you’ve earned, the commission advantages you’ve obtained with the Poseidon and Zeus characters, and how much star cash reward will be credited to your account on Wednesday.",
    "images": [
      {
        "src": "/image/image5.png"
      }
    ]
  },
  {
    "title": "Your Affiliate Network?",
    "description": "In this section, you can follow the star status of people who registered using your referral code or link, with real-time updates.",
    "images": [
      {
        "src": "/image/image6.png"
      }
    ]
  },
  {
    "title": "What is the Star Race?",
    "description": "As your star count increases, you move into VIP customer status and enjoy exclusive benefits based on your stars. Your star advantages will be automatically credited to your account without any requests. Each Wednesday at 00:01, a cash reward is credited to your account based on your star count, and depending on your star count, you’ll pay lower commissions on coupons placed by Poseidon and receive higher discounts (refunds) on any losses in Zeus slots.",
    "images": [
      {
        "src": "/image/image7.png"
      }
    ]
  },
  {
    "title": "What is a Ticket?",
    "description": "This section allows you to directly communicate with our professional team to find solutions to any issues. Your requests will be responded to within 10 to 45 minutes, depending on the level of demand.",
    "images": [
      {
        "src": "/image/image8.png"
      }
    ]
  },
  {
    "title": "Why Should I Use Telegram?",
    "description": "Using Telegram allows you to receive notifications instantly as shown in the image. We recommend activating the Telegram bot if you want to be informed about campaigns and advantages beforehand. Additionally, members who activate Telegram earn extra stars.",
    "images": [
      {
        "src": "/image/image9.png"
      }
    ]
  },
  {
    "title": "Coupons Played by Poseidon?",
    "description": "In this section, you can track the coupons played by the Poseidon character. If you see all the matches in green, Poseidon has won, and you lose money. However, if one match is red, Poseidon has lost, which means you win. In this scenario, you are the house, so red matches (lost bets) are your gains.",
    "images": [
      {
        "src": "/image/image10.png"
      }
    ]
  },
  {
    "title": "Why Should I Use Usd-T (Tether)?",
    "description": "The money sent to you can reach you easily within minutes, no matter where you are in the world, and you can make your deposits and withdrawals with 0 commission. Additionally, members who use Usd-T (Tether) earn extra stars.",
    "images": [
      {
        "src": "/image/image11.png"
      }
    ]
  },
  {
    "title": "What Does a Star Gain?",
    "description": "The more stars members have, the easier it is for them to earn money. Each star makes you more advantageous. The system automatically grants the advantages according to the number of stars you have, without any need for a request.",
    "images": [
      {
        "src": "/image/image12.png"
      }
    ]
  },
  {
    "title": "Example of Star Earnings:",
    "description": "You pay less commission to Poseidon according to the number of stars you have. For example, a member with 100 stars will receive a 100% discount and will not have to pay any commission to Poseidon. Also, if you experience a loss in Zeus slots, you will receive a higher discount (refund). The system automatically applies such discounts to your account, and there is no need for you to make a request.",
    "images": [
      {
        "src": "/image/image13.png"
      }
    ]
  },
  {
    "title": "What is the Logic Behind Poseidon?",
    "description": "In traditional betting sites, if your parlay fails due to one match, the fact that you guessed the others correctly doesn’t matter—you still lose. Based on the saying 'The house always wins,' here, Poseidon is a player, and you are invited to be the house. Now, everything is reversed; even if you guess just one match correctly, you win. You choose the match, the selection, and the amount to bet, and Poseidon places the bet. The goal here is for Poseidon to lose, so you win.",
    "images": [
      {
        "src": "/image/image14.png"
      }
    ]
  },
  {
    "title": "Why Should Poseidon Lose?",
    "description": "You determine the amount and the selection for Poseidon to bet on. Without being bound by conditions, you can create your own strategy to make Poseidon lose. Even if Poseidon guesses one of the matches you select correctly, you still win. As your star count increases, the commission you pay to Poseidon becomes more favorable.",
    "images": [
      {
        "src": "/image/image15.png"
      }
    ]
  },
  {
    "title": "Bank?",
    "description": "In this section, you can review all your transactions, including how much money you have deposited and withdrawn, and your losses and gains. If you have any questions, don't hesitate to contact support.",
    "images": [
      {
        "src": "/image/image16.png"
      }
    ]
  },
  {
    "title": "What is a Block?",
    "description": " The blocked amount is the portion of the bet placed by the character Poseidon, where the risk of winning is held. If the bet placed by Poseidon loses (meaning you win), the blocked amount is returned to your main balance along with your winnings after the matches conclude. If the bet placed by Poseidon wins, you lose, and the blocked amount is not returned. If you want to see the account statements related to this issue, you can visit the Cashier section.",
    "images": [
      {
        "src": "/image/image17.png"
      }
    ]
  }
  
]

};

const Tutorial = ({ tutorialopen, setTutorialOpen, tutorialid,selectedLanguage,setTutorialid }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    console.log("Tutorial component mounted");
    const tutorialSeen = Cookies.get('tutorial') === 'false';
    if (tutorialSeen) {
      setTutorialOpen(false);
    }
  }, [setTutorialOpen]);

  useEffect(() => {
    console.log("Current tutorial id:", tutorialid);
    setLoading(true);
    let currentData
    if(tutorialData[selectedLanguage]){
      currentData=tutorialData[selectedLanguage][tutorialid];
    }else{
      currentData=tutorialData.en[tutorialid];
    }
    if (currentData?.images) {
      preloadImages(currentData.images);
    }
  }, [tutorialid]);

  const handleClose = () => {
    setTutorialOpen(false);
    Cookies.set('loginfirst', '2', { expires: 365 });
    if (dontShowAgain) {
      Cookies.set('tutorial', '2', { expires: 365 });
    }
  };

  const handleClose2 = () => {
    setTutorialOpen(false);
    Cookies.set('loginfirst', '2', { expires: 365 });
    Cookies.set('tutorial', '2', { expires: 365 });

  };


  const handleDontShowAgainChange = (event) => {
    console.log("DontShowAgain checkbox changed", event.target.checked);
    setDontShowAgain(event.target.checked);
  };

  const preloadImages = (images) => {
    let loadedImages = 0;
    images.forEach(image => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          setLoading(false);
        }
      };
      img.onerror = () => {
        loadedImages += 1;
        if (loadedImages === images.length) {
          setLoading(false);
        }
      };
    });
  };
  let currentData
  if(tutorialData[selectedLanguage]){
    currentData=tutorialData[selectedLanguage][tutorialid];
  }else{
    currentData=tutorialData.en[tutorialid];
  }
 

  return (
    <Modal
      open={tutorialopen}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          maxWidth: '900px',
          width: '90%',
          height: tutorialid == 17 ? isMobile ? '70%' : "40%" : isMobile ? '80%' : "auto",
          maxHeight: '800px',
          overflowY: tutorialid == 17 ? 'hidden' : 'auto',
          overflowX: 'hidden',
          position: 'relative',
          backgroundColor: "#d79f09",
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px'
          }
        }}
      >
        <Grid container justifyContent={"space-between"} alignContent={"center"} sx={{ width: "97%" }}>
          <Grid item sx={{ width: "33%" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item sx={{ width: "25%" }}>
            <Typography variant="h6" component="h5" textAlign="center" sx={{ marginTop: "5px" }}>
              {translatedata[selectedLanguage]?.tutorial?.learning}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "33%", textAlign: "end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={handleDontShowAgainChange}
                  name="dontShowAgain"
                  sx={{
                    '&.Mui-checked': {
                      color: '#202504'
                    }
                  }}
                />
              }
              sx={{ marginLeft: "10px" }}
              label={
                <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                 {translatedata[selectedLanguage].tutorial.dontshowagain}
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            height: tutorialid == 17 ? "100%" : "auto",
            p: 4,
            position: 'relative'
          }}
        >
          {tutorialid == 17 ?

            <Box textAlign="center">
              <Typography variant="h5" component="h5">
              {translatedata[selectedLanguage].tutorial.welcometitle}
              </Typography>
              <Typography >
              {translatedata[selectedLanguage].tutorial.welcometext}
              </Typography>
              <Box mt={2} display="flex" justifyContent="center" gap={2}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => { setTutorialid(13) }}
                  style={{ width: '150px', height: isMobile ? "100px" : '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: isMobile && "0.6rem" }}
                >
                  <PlayArrowIcon style={{ fontSize: isMobile ? "24px" : '48px' }} />
                  {translatedata[selectedLanguage].tutorial.next}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => { handleClose }}
                  style={{ width: '150px', height: isMobile ? "100px" : '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: isMobile && "0.6rem" }}
                >
                  <SkipNextIcon style={{ fontSize: isMobile ? "24px" : '48px' }} />
                  {translatedata[selectedLanguage].tutorial.nownext}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => { handleClose2() }}
                  style={{ width: '150px', height: isMobile ? "100px" : '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: isMobile && "0.6rem" }}
                >
                  <BlockIcon style={{ fontSize: isMobile ? "24px" : '48px' }} />
                  {translatedata[selectedLanguage].tutorial.pass}
                </Button>
              </Box>
            </Box>

            :
            <>
              <Typography variant="h5" component="h5" textAlign="center">
                {currentData.title}
              </Typography>
              <Typography sx={{ mt: 2, textAlign: 'center' }}>
                {currentData.description}
              </Typography>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {currentData.images.map((image, index) => (
                    <Grid item xs={12} md={12} sm={12} lg={12} key={index}>
                      <Box textAlign="center">
                        <img
                          src={image.src}
                          alt={`Görsel ${index + 1}`}
                          style={{  borderRadius: 8, maxHeight: '300px', objectFit: 'cover' }}
                        />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {image.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              {tutorialid == 0 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(4) }}>
                  {translatedata[selectedLanguage].tutorial.startable}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(11) }}>
                  {translatedata[selectedLanguage].tutorial.starhatwin}
                  </Button>
                </Box>}
              {tutorialid == 1 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(0) }}>
                  {translatedata[selectedLanguage].tutorial.starwhatup}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(12) }}>
                  {translatedata[selectedLanguage].tutorial.starcasesimple}
                  </Button>
                </Box>
              }
              {tutorialid == 2 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(2) }}>
                  {translatedata[selectedLanguage].tutorial.starwhatwin}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(6) }}>
                  {translatedata[selectedLanguage].tutorial.paymentmeth}
                  </Button>
                </Box>
              }
              {tutorialid == 3 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(6) }}>
                  {translatedata[selectedLanguage].tutorial.whatstarrace}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(4) }}>
                  {translatedata[selectedLanguage].tutorial.startable}
                  </Button>
                </Box>
              }
              {
                tutorialid == 4 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(3) }}>
                  {translatedata[selectedLanguage].tutorial.startask}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(0) }}>
                  {translatedata[selectedLanguage].tutorial.starwhatup}
                  </Button>
                </Box>
              }

              {
                tutorialid == 5 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(12) }}>
                  {translatedata[selectedLanguage].tutorial.starwin}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(7) }}>
                  {translatedata[selectedLanguage].tutorial.whtticket}
                  </Button>
                </Box>
              }
                            {
                tutorialid == 6 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(13) }}>
                  {translatedata[selectedLanguage].tutorial.poseidonwht}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(3) }}>
                  {translatedata[selectedLanguage].tutorial.startask}
                  </Button>
                </Box>
              }
                                          {
                tutorialid == 7 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(5) }}>
                  {translatedata[selectedLanguage].tutorial.telegram}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(8) }}>
                  {translatedata[selectedLanguage].tutorial.affnetwork}
                  </Button>
                </Box>
              }
                                                        {
                tutorialid == 8 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(7) }}>
                  {translatedata[selectedLanguage].tutorial.telegram}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(15) }}>
                  {translatedata[selectedLanguage].tutorial.whatcase}
                  </Button>
                </Box>
              }
              {
                tutorialid == 10 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(15) }}>
                  {translatedata[selectedLanguage].tutorial.whatcase}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(13) }}>
                  {translatedata[selectedLanguage].tutorial.poseidonwht}
                  </Button>
                </Box>
              }
                            {
                tutorialid == 11 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(0) }}>
                  {translatedata[selectedLanguage].tutorial.starwhatup}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(1) }}>
                  {translatedata[selectedLanguage].tutorial.starcasesimple}
                  </Button>
                </Box>
              }
               {
                tutorialid == 12 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(1) }}>
                  {translatedata[selectedLanguage].tutorial.starcase}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(5) }}>
                  {translatedata[selectedLanguage].tutorial.affnetwork}
                  </Button>
                </Box>
              }
                             {
                tutorialid == 13 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                    <Button variant="contained" color="primary" onClick={() => { setTutorialid(16) }}>
                                    {translatedata[selectedLanguage].tutorial.goback}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(14) }}>
                  {translatedata[selectedLanguage].tutorial.whatposeidonlost}
                  </Button>
                </Box>
              }
              {
                tutorialid == 14 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(13) }}>
                  {translatedata[selectedLanguage].tutorial.poseidonwht}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(6) }}>
                  {translatedata[selectedLanguage].tutorial.whatstarrace}
                  </Button>
                </Box>
              }
                            {
                tutorialid == 15 &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(8) }}>
                  {translatedata[selectedLanguage].tutorial.telegram}
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => { setTutorialid(10) }}>
                  {translatedata[selectedLanguage].tutorial.whtusdt}
                  </Button>
                </Box>
              }
            </>
          }
        </Box>
      </Box>
    </Modal>
  );
};

export default Tutorial;
