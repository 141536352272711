import React, { useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert, IconButton, InputAdornment,Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import translatedata from '../translate.json';
const RegisterDialog = ({ open, onClose,promocodes,selectedLanguage }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnack, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [promocode2,setPromoCodes] = useState('');
  const [stat, setStat] = useState('error');

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setPromoCodes(promocodes)
  }, [promocodes])
  
  const handleRegister = () => {
    axios.post("https://api.youbetshop.com/register", {name:name,surname:surname, email: email, pass: password, pass2: confirmPassword,promo_code:promocode2,user_type:1})
      .then(response => {
        if (response) {
          if (response.data.success === true) {
            setMessage(translatedata[selectedLanguage].registerdialog.registersuccess);
            setOpen(true);
            setStat("success");
            onClose();
          } else {
            setMessage(response.data.data.message || translatedata[selectedLanguage].registerdialog.registererror);
            setOpen(true);
            setStat("error");
          }
        } else {
          setMessage(translatedata[selectedLanguage].registerdialog.registererror);
          setOpen(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage(translatedata[selectedLanguage].registerdialog.registererror);
        setOpen(true);
        setStat("error");
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{translatedata[selectedLanguage].registerdialog.register}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6} md={6} sm={6} lg={6}>
        <TextField
            autoFocus
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.name}
            type="name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
               </Grid>  <Grid item xs={6} md={6} sm={6} lg={6}>   <TextField
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.surname}
            type="surname"
            fullWidth
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          </Grid>
          </Grid>
          <TextField
            
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.email}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.pass}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.repass}
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
            <TextField
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.promocodetext}
            type="promo"
            fullWidth
            value={promocode2}
            onChange={(e) => setPromoCodes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">{translatedata[selectedLanguage].registerdialog.cancel}</Button>
          <Button onClick={handleRegister} color="primary">{translatedata[selectedLanguage].registerdialog.register}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterDialog;
