import React, { useState, useEffect,useRef } from 'react';
import {
    Container, Box, Typography, TextField, Button, Grid, Paper, List, ListItem, ListItemText,
    useMediaQuery, useTheme, Modal,Snackbar,Alert, AppBar, Toolbar,IconButton
} from '@mui/material';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import translatedata from '../translate.json';
import Qusetion from '../media/question.gif';
import Tutorial from '../component/Tutorial';
import Chat from '../media/chat.gif';
import Whatsapp from '../media/whatsapp.png';
import { GroupWork } from '@mui/icons-material';
function App({selectedLanguage,setChatOpen}) {
    const [tickets, setTickets] = useState([]);
    const [ticketid, setTicketid] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [newTicketTitle, setNewTicketTitle] = useState('');
    const [newTicketMessage, setNewTicketMessage] = useState('');
    const [tickettitle, setTicketTitle] = useState('');
    const [ticketmessage, setTicketMessage] = useState([]);
    const theme = useTheme();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open,setOpen]=useState(false);
    const [message,setMessage]=useState(false);
    const [stat,setStat]=useState(false);
    const messagesBoxRef = useRef(null);
    const [userticketMessage, setUserTicketMessage] = useState('');
    const [tutorialid, setTutorialid] = useState(7);
    const [tutorialopen, setTutorialOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        if (cookies.serviceToken) {
            axios
                .get("https://api.youbetshop.com/api/ticket/get-ticket-user", config)
                .then(function (response) {
                    setTickets(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [cookies.serviceToken]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const recheck = ()=>{
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        if (cookies.serviceToken) {
            axios
                .get("https://api.youbetshop.com/api/ticket/get-ticket-user", config)
                .then(function (response) {
                    setTickets(response.data.data);
                    if(ticketid){
                        const tic=response.data.data.find((item)=>item.id==ticketid)
                        setTicketTitle(tic?.title);
                        setTicketMessage(tic?.messages)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    const handleCreateTicket = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios
        .post("https://api.youbetshop.com/api/ticket/create-ticket", {"title":newTicketTitle,"message":newTicketMessage}, config)
        .then(function (response) {
            if(response.data.success){
                setOpen(true)
                setStat("success")
                setMessage(translatedata[selectedLanguage].notify.successticket)
                recheck();
                handleClose();
            }else{
                setOpen(true)
                setStat("error")
                setMessage(translatedata[selectedLanguage].notify.errorticket)
                handleClose();
            }
            
        })
        .catch(function (error) {
            setOpen(true)
            setStat("error")
            setMessage(error)
            handleClose();
            console.log(error);
        });

      
    };
    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const getStatus =(id)=>{
        if(id==0){
            return translatedata[selectedLanguage].help.ticketcreate;
        }else if(id==1){
            return translatedata[selectedLanguage].help.adminres;
        }else if(id==2){
            return translatedata[selectedLanguage].help.reswait;
        }else{
            return translatedata[selectedLanguage].help.closed;
        }
      }
      const openTicket=(id)=>{
        setTicketid(id);
      }
      useEffect(() => {
       const tic=tickets.find((item)=>item.id==ticketid)
       setTicketTitle(tic?.title);
       setTicketMessage(tic?.messages)
       recheck();
       setTimeout(() => {
       if (messagesBoxRef.current) {
        const { scrollHeight, clientHeight } = messagesBoxRef.current;
        messagesBoxRef.current.scrollTop = scrollHeight - clientHeight+1000;
    } 
}, 500);
      }, [ticketid])
      
      const handleSendMessage = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios
        .post("https://api.youbetshop.com/api/ticket/res-ticket-user", {"ticket_id":ticketid,"message":userticketMessage}, config)
        .then(function (response) {
            if(response.data.success){

                recheck();
                handleClose();
                setUserTicketMessage('');
                setTimeout(() => {
                    if (messagesBoxRef.current) {
                        const { scrollHeight, clientHeight } = messagesBoxRef.current;
                        messagesBoxRef.current.scrollTop = scrollHeight - clientHeight+1000;
                    } 
                }, 500);

            }else{
                setOpen(true)
                setStat("error")
                setMessage(translatedata[selectedLanguage].notify.errormessage)
                handleClose();
            }
            
        })
        .catch(function (error) {
            setOpen(true)
            setStat("error")
            setMessage(error)
            handleClose();
            console.log(error);
        });
     
    };
    const opentutorial = (id) => {
        setTutorialOpen(true);
        setTutorialid(id);
      }
      const goWp = () => {
        const phoneNumber = '+447437586218';
        const url = `https://wa.me/${phoneNumber}`;
    
        window.open(url, '_blank');
      };
    return (
        <>
    <AppBar position="static">
        <Toolbar>
          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
            {translatedata[selectedLanguage].help.home}
            </Typography>
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => setChatOpen(true)}>
            <img src={Chat} style={{width:"25px"}}/>
            <Typography variant="h6">
            {"Chat"}
            </Typography>
          </IconButton>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
          {translatedata[selectedLanguage].help.support}
          </Typography>
          </Box>
        </Toolbar>
      </AppBar>
        <Container sx={{ my: 4 }}>
                            <Box sx={{justifyContent:"space-between",alignItems:"center",backgroundColor:"#2d2d2db8",marginBottom:"5px",display:"flex"}}>
                            <Typography variant="h4" sx={{color: "#d79f09" }}>
                            {translatedata[selectedLanguage].help.support}
                            </Typography>
                            <img src={Qusetion} style={{ maxWidth: "75px", display: "flex" }} onClick={() => opentutorial(7)} />
                            </Box>
            <Box >
                <Grid container spacing={2}>
                    {isMobile && cookies.serviceToken &&
                        <Grid item xs={12} md={4}>
                            {ticketid ?
                                <Paper sx={{ p: 2, height: '100%',backgroundColor:"#ededed" }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" gutterBottom>
                                        {translatedata[selectedLanguage].help.ticket}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: "#125f09"
                                            }}
                                            onClick={()=>setTicketid()}
                                        >{translatedata[selectedLanguage].help.back}</Button>
                                    </Grid>
                                    <Box  ref={messagesBoxRef}  sx={{ height: '60vh', overflow: 'auto', mb: 2, p: 2, border: '1px solid #ccc',backgroundColor:"#ece4d4" }}>
                                        <Typography variant="body2" color="textSecondary">
                                        {translatedata[selectedLanguage].help.helpdesc}
                                        </Typography>
                                        <Box mt={2}>
                                            {ticketmessage && ticketmessage?.map((item)=>(
                                                item?.user_type==1?
                                                    <Grid container sx={{marginTop:"5px"}}>
                                                        <Grid item xs={8} md={8} sm={8} lg={8} sx={{padding:"5px",borderRadius:"5px",backgroundColor:"#ffe3b0"}} >
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{fontWeight:"600"}}>{"Admin:"}</Typography>
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"Start"}}>{item.message}</Typography>
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"end",fontSize:"0.55rem"}}>{item.time}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid container sx={{marginTop:"5px"}}>
                                                    <Grid item xs={4} md={4} sm={4} lg={4}>
                                                        {""}
                                                    </Grid>
                                                        <Grid item xs={8} md={8} sm={8} lg={8} sx={{padding:"5px",borderRadius:"5px",backgroundColor:"#b0ffbd"}}>
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{fontWeight:"600"}}>{"User:"}</Typography>
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"Start"}}>{item.message}</Typography>
                                                        <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"end",fontSize:"0.55rem"}}>{item.time}</Typography>
                                                    </Grid>
                                                    </Grid>
                                            ))}
                                             
                                        </Box>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder={translatedata[selectedLanguage].help.placeholder}
                                        multiline
                                        rows={4}
                                        sx={{ mb: 2 }}
                                        value={userticketMessage}
                                        onChange={(e) => setUserTicketMessage(e.target.value)}
                                    />
                                    <Button variant="contained" color="primary" fullWidth onClick={handleSendMessage}>
                                    {translatedata[selectedLanguage].help.send}
                                    </Button>
                                </Paper>
                                :
                                <Paper sx={{ p: 2, height: '100%',backgroundColor:"#ededed" }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" gutterBottom>
                                        {translatedata[selectedLanguage].help.tickets}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: "#125f09"
                                            }}
                                            onClick={handleOpen}
                                        >{"Yeni Ticket"}</Button>
                                    </Grid>
                                    <Box sx={{ height: '60vh', overflow: 'auto', mb: 2,  border: '1px solid #ccc',borderRadius:"5px",backgroundColor:"#ece4d4" }}>
                                    <Grid container sx={{marginBottom:"5px",borderBottom:"solid 1px",padding:"3px",backgroundColor:"#d79f09"}}>
                                                  <Grid item xs={5} md={5} sm={5} lg={5} >
                                                  <Typography   color="white">
                                                  {translatedata[selectedLanguage].help.title}
                                                     </Typography>
                                                  </Grid>
                                                  <Grid item xs={4} md={4} sm={4} lg={4} >
                                                  <Typography    color="white">
                                                        {translatedata[selectedLanguage].help.status}
                                                     </Typography>
                                                  </Grid>
                                            </Grid>
                                        {tickets.map((ticket) => (
                                            <Grid container sx={{marginBottom:"5px",borderBottom:"solid 1px",padding:"3px",justifyContent:"center",alignItems:"center"}}>
                                                  <Grid item xs={5} md={5} sm={5} lg={5} >
                                                  <Typography key={ticket.id} sx={{fontSize:"0.8rem"}}  color="textSecondary">
                                                        {ticket.title}
                                                     </Typography>
                                                  </Grid>
                                                  <Grid item xs={4} md={4} sm={4} lg={4} >
                                                  <Typography key={ticket.id}  sx={{fontSize:"0.8rem"}} color="textSecondary">
                                                        {getStatus(ticket.status)}
                                                     </Typography>
                                                  </Grid>
                                                  <Grid item xs={3} md={3} sm={3} lg={3} >
                                                        <Button variant="contained" sx={{fontSize:"0.8rem"}} size="small" onClick={()=>openTicket(ticket.id)}>
                                                            {translatedata[selectedLanguage].help.messages}
                                                        </Button>
                                                  </Grid>
                                            </Grid>

                                        ))}
                                    </Box>
                                    <Box sx={{width:"100%",display:"flex",alignItems:"center",backgroundColor:"#25D366",borderRadius:"7px",padding:"5px"}} onClick={()=>goWp()}>
                                        <img src={Whatsapp} style={{maxWidth:"50px",marginRight:"5px"}} />
                                        <Typography variant='h5'>Whatsapp</Typography>
                                    </Box>
                                </Paper>
                            }
                        </Grid>}
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed"}}>
                            <Typography variant="h6" gutterBottom>
                            {translatedata[selectedLanguage].help.quest1}
                                
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res1} />
                                </ListItem>
                                {/* <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res2} />
                                </ListItem> */}
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res3} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res4} />
                                </ListItem>

                            </List>
                        </Paper>

                        {/* <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
                            <Typography variant="h6" gutterBottom>
                            {translatedata[selectedLanguage].help.quest21}
                            </Typography>
                            <Typography paragraph>
                            {translatedata[selectedLanguage].help.quest22}
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res5} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res6} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res7} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res8} />
                                </ListItem>
                            </List>
                        </Paper> */}

                        <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
                            <Typography variant="h6" gutterBottom>
                            {translatedata[selectedLanguage].help.text1}
                                
                            </Typography>
                            <Typography paragraph>
                            {translatedata[selectedLanguage].help.text2}
                            </Typography>
                        </Paper>
                        {/* <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text3}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text4}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text5}
        </Typography>
    </Paper> */}

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text6}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text7}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text5}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text8}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text7}
        </Typography>
        <Typography paragraph>
            {translatedata[selectedLanguage].help.text5}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text9}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text10}

            {translatedata[selectedLanguage].help.text5}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text11}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text12}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text13}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text14}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text15}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text16}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text17}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text18}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text19}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text20}
        </Typography>
    </Paper>

    <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
        <Typography variant="h6" gutterBottom>
        {translatedata[selectedLanguage].help.text21}
        </Typography>
        <Typography paragraph>
        {translatedata[selectedLanguage].help.text22}
        </Typography>
    </Paper>
                    </Grid>
                    {!isMobile && cookies.serviceToken &&
                        <Grid item xs={12} md={4}>
                        {ticketid ?
                            <Paper sx={{ p: 2, height: '100%',backgroundColor:"#ededed"  }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" gutterBottom>
                                    {translatedata[selectedLanguage].help.ticket}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            backgroundColor: "#125f09"
                                        }}
                                        onClick={()=>setTicketid()}
                                    >{"Geri"}</Button>
                                </Grid>
                                <Box ref={messagesBoxRef}  sx={{ height: '60vh', overflow: 'auto', mb: 2, p: 2, border: '1px solid #ccc',backgroundColor:"#ece4d4" }}>
                                    <Typography variant="body2" color="textSecondary">
                                    {translatedata[selectedLanguage].help.text23}
                                    </Typography>
                                    <Box mt={2}>
                                        {ticketmessage && ticketmessage?.map((item)=>(
                                            item?.user_type==1?
                                                <Grid container sx={{marginTop:"5px"}}>
                                                    <Grid item xs={8} md={8} sm={8} lg={8} sx={{padding:"5px",borderRadius:"5px",backgroundColor:"#ffe3b0"}} >
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{fontWeight:"600"}}>{"Admin:"}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"Start"}}>{item.message}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"end",fontSize:"0.55rem"}}>{item.time}</Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container sx={{marginTop:"5px"}}>
                                                <Grid item xs={4} md={4} sm={4} lg={4}>
                                                    {""}
                                                </Grid>
                                                    <Grid item xs={8} md={8} sm={8} lg={8} sx={{padding:"5px",borderRadius:"5px",backgroundColor:"#b0ffbd"}}>
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{fontWeight:"600"}}>{"User:"}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"Start"}}>{item.message}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary" sx={{textAlign:"end",fontSize:"0.55rem"}}>{item.time}</Typography>
                                                </Grid>
                                                </Grid>
                                        ))}
                                         
                                    </Box>
                                </Box>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={translatedata[selectedLanguage].help.placeholder}
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                    value={userticketMessage}
                                    onChange={(e) => setUserTicketMessage(e.target.value)}
                                />
                                <Button variant="contained" color="primary" fullWidth onClick={handleSendMessage}>
                                {translatedata[selectedLanguage].help.send}
                                </Button>
                            </Paper>
                            :
                            <Paper sx={{ p: 2, height: '100%',backgroundColor:"#ededed"  }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" gutterBottom>
                                    {translatedata[selectedLanguage].help.tickets}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            backgroundColor: "#125f09"
                                        }}
                                        onClick={handleOpen}
                                    >{"Yeni Ticket"}</Button>
                                </Grid>
                                <Box sx={{ height: '60vh', overflow: 'auto', mb: 2,  border: '1px solid #ccc',borderRadius:"5px",backgroundColor:"#ece4d4"  }}>
                                <Grid container sx={{marginBottom:"5px",borderBottom:"solid 1px",padding:"3px",backgroundColor:"#d79f09"}}>
                                              <Grid item xs={5} md={5} sm={5} lg={5} >
                                              <Typography   color="white">
                                              {"Başlık"}
                                                 </Typography>
                                              </Grid>
                                              <Grid item xs={4} md={4} sm={4} lg={4} >
                                              <Typography    color="white">
                                                    {"Durumu"}
                                                 </Typography>
                                              </Grid>
                                        </Grid>
                                    {tickets.map((ticket) => (
                                        <Grid container sx={{marginBottom:"5px",borderBottom:"solid 1px",padding:"3px",justifyContent:"center",alignItems:"center"}}>
                                              <Grid item xs={5} md={5} sm={5} lg={5} >
                                              <Typography key={ticket.id} sx={{fontSize:"0.8rem"}}  color="textSecondary">
                                                    {ticket.title}
                                                 </Typography>
                                              </Grid>
                                              <Grid item xs={4} md={4} sm={4} lg={4} >
                                              <Typography key={ticket.id}  sx={{fontSize:"0.8rem"}} color="textSecondary">
                                                    {getStatus(ticket.status)}
                                                 </Typography>
                                              </Grid>
                                              <Grid item xs={3} md={3} sm={3} lg={3} >
                                                    <Button variant="contained" sx={{fontSize:"0.8rem"}} size="small" onClick={()=>openTicket(ticket.id)}>
                                                    {translatedata[selectedLanguage].help.messages}
                                                    </Button>
                                              </Grid>
                                        </Grid>

                                    ))}
                                </Box>
                                <Box sx={{width:"100%",display:"flex",alignItems:"center",backgroundColor:"#25D366",borderRadius:"7px",padding:"5px"}} onClick={()=>goWp()}>
                                        <img src={Whatsapp} style={{maxWidth:"50px",marginRight:"5px"}} />
                                        <Typography variant='h5'>Whatsapp</Typography>
                                    </Box>
                            </Paper>
                        }
                    </Grid>}
                </Grid>
            </Box>
            <Modal open={openModal} onClose={handleClose}>
                <Paper sx={{ p: 4, width: isMobile ? '90%' : '40%', margin: 'auto', mt: isMobile ? 4 : 10 }}>
                    <Typography variant="h6" gutterBottom>
                    {translatedata[selectedLanguage].help.newticket}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={translatedata[selectedLanguage].help.title}
                        value={newTicketTitle}
                        onChange={(e) => setNewTicketTitle(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={translatedata[selectedLanguage].help.message}
                        multiline
                        rows={4}
                        value={newTicketMessage}
                        onChange={(e) => setNewTicketMessage(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleCreateTicket}>
                    {translatedata[selectedLanguage].help.send}
                    </Button>
                </Paper>
            </Modal>
        </Container>
        
                <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
              >
                <Alert
            onClose={handleCloseSnack}
            severity={stat}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        
          </Snackbar>
          <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
          </>
    );
}

export default App;
