import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import Qusetion from '../media/question.gif';
import Tutorial from '../component/Tutorial';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    AppBar, Toolbar, IconButton, Typography, Container, Box, Grid, Snackbar, Alert, Stepper, Step, Checkbox, StepButton, useMediaQuery, useTheme, FormControlLabel, InputAdornment, TextField, Button, FormControl, Select, MenuItem, InputLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import moment from 'moment';
import "dayjs/locale/tr";
import translatedata from '../translate.json';
import dayjs from 'dayjs';
import Chat from '../media/chat.gif';
import countrys from './country.json';
import check from '../img/check.png';

import "dayjs/locale/tr";
const Register = ({ selectedLanguage, setChatOpen,setLoginOpen }) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [birthday, setBirthday] = useState();
    const [surname, setSurname] = useState('');
    const [password, setPassword] = useState('');
    const [tckn, setTckn] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [promocode2, setPromoCodes] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [adress, setAdress] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [countrycode,setCountryCode]= useState("90");
    const [phone,setPhone]= useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = useState({});
    const [inputerror, setInputError] = useState([]);
    const [stepcheck, setStepCheck] = useState(false);
    const [openSnack, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [stat, setStat] = useState('error');
    const steps = translatedata[selectedLanguage].registerdialog.steps;
    const countryall=countrys
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const validateTCNo = async (tc) => {
        const tcObj = inputerror.find(item => item.id === "tc");
    
        if (tc?.length !== 11 || isNaN(tc)) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "tc" ? { ...item, value: false, message: "Lütfen geçerli bir Id no girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "tc", value: false, message: "Lütfen geçerli bir Id no girin." }]);
            }
            return false;
        }
    
        let sumOdd = 0;
        let sumEven = 0;
        let sumAll = 0;
    
        for (let i = 0; i < 9; i++) {
            const num = parseInt(tc[i], 10);
            if (i % 2 === 0) {
                sumOdd += num;
            } else {
                sumEven += num;
            }
            sumAll += num;
        }
    
        const tenthDigit = (sumOdd * 7 - sumEven) % 10;
        const eleventhDigit = (sumAll + parseInt(tc[9], 10)) % 10;
    
        if (tenthDigit === parseInt(tc[9], 10) && eleventhDigit === parseInt(tc[10], 10)) {
            try {
                const response = await axios.post("https://api.youbetshop.com/registercontrol", { tcno: tc });
    
                if (response?.data?.success === true) {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "tc" ? { ...item, value: true } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "tc", value: true }]);
                    }
                    return true;
                } else {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "tc" ? { ...item, value: false, message: response?.data?.message } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "tc", value: false, message: response?.data?.message }]);
                    }
                    return false;
                }
            } catch (error) {
                console.error('Error with API request:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext(translatedata[selectedLanguage].withdraw.error);
                return false;
            }
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "tc" ? { ...item, value: false, message: "Lütfen geçerli bir Id No girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "tc", value: false, message: "Lütfen geçerli bir Id No girin." }]);
            }
            return false;
        }
    };
    
    async function ValidatePassword(password) {
        const lengthCheck = password?.length >= 8;
        const upperCaseCheck = /[A-Z]/.test(password);
        const lowerCaseCheck = /[a-z]/.test(password);
        const specialCharacterCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        if (lengthCheck && upperCaseCheck && lowerCaseCheck && specialCharacterCheck) {
            const tcObj = inputerror.find(item => item.id === "pass1");
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "pass1" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "pass1", value: true }]);
            }
            return true;
        } else {
            const tcObj = inputerror.find(item => item.id === "pass1");
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "pass1" ? { ...item, value: false, message: "Lütfen geçerli şifre girin.En az 8 karakter,1 büyük,1 küçük harf ve 1 özel karakter girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "pass1", value: false, message: "Lütfen geçerli şifre girin.En az 8 karakter,1 büyük,1 küçük harf ve 1 özel karakter girin." }]);
            }
            return false;
        }
    }
    
    async function ValidatePass2(confirmPassword, password) {
        const tcObj = inputerror.find(item => item.id === "pass2");
        if (confirmPassword == password) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "pass2" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "pass2", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "pass2" ? { ...item, value: false, message: "Şifreler eşleşmiyor." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "pass2", value: false, message: "Şifreler eşleşmiyor." }]);
            }
            return false;
        }
    }
    
    const CheckUsername = async (username) => {
        const tcObj = inputerror.find(item => item.id === "username");
        const lengthCheck = username?.length >= 5;
        const alphanumericCheck = /^[a-zA-Z0-9]+$/.test(username);
        if (lengthCheck && alphanumericCheck) {
            try {
                const response = await axios.post("https://api.youbetshop.com/registercontrol", { username: username });
                if (response?.data?.success == true) {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "username" ? { ...item, value: true } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "username", value: true }]);
                    }
                    return true;
                } else {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "username" ? { ...item, value: false, message: response?.data?.message } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "username", value: false, message: response?.data?.message }]);
                    }
                    return false;
                }
            } catch (error) {
                console.error('Error with API requests:', error);
                if (tcObj) {
                    setInputError(prevState =>
                        prevState.map(item =>
                            item.id === "username" ? { ...item, value: false, message: error } : item
                        )
                    );
                } else {
                    setInputError(prevState => [...prevState, { id: "username", value: false, message: error }]);
                }
                return false;
            }
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "username" ? { ...item, value: false, message: "Username minimum 5 hane ve sadece harf,rakamlardan oluşmalı." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "username", value: false, message: "Username minimum 5 hane ve sadece harf,rakamlardan oluşmalı." }]);
            }
            return false;
        }
    }
    
    const ValidateEmail = async (email) => {
        const tcObj = inputerror.find(item => item.id === "mail");
        const emailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (emailCheck) {
            try {
                const response = await axios.post("https://api.youbetshop.com/registercontrol", { email: email });
                if (response?.data?.success == true) {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "mail" ? { ...item, value: true } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "mail", value: true }]);
                    }
                    return true;
                } else {
                    if (tcObj) {
                        setInputError(prevState =>
                            prevState.map(item =>
                                item.id === "mail" ? { ...item, value: false, message: response?.data?.message } : item
                            )
                        );
                    } else {
                        setInputError(prevState => [...prevState, { id: "mail", value: false, message: response?.data?.message }]);
                    }
                    return false;
                }
            } catch (error) {
                console.error('Error with API requests:', error);
                if (tcObj) {
                    setInputError(prevState =>
                        prevState.map(item =>
                            item.id === "mail" ? { ...item, value: false, message: error } : item
                        )
                    );
                } else {
                    setInputError(prevState => [...prevState, { id: "mail", value: false, message: error }]);
                }
                return false;
            }
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "mail" ? { ...item, value: false, message: "Lütfen geçerli bir mail adresi girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "mail", value: false, message: "Lütfen geçerli bir mail adresi girin." }]);
            }
            return false;
        }
    }
    
    const BirthdayCheck = async (birthday) => {
        const tcObj = inputerror.find(item => item.id === "birthday");
    
        const birthMoment = dayjs(birthday).startOf('day');
        const today = dayjs().startOf('day');
        const age = today.diff(birthMoment, 'years');
        if (age >= 18) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "birthday" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "birthday", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "birthday" ? { ...item, value: false, message: "Sitemize sadece 18 yaşından büyükler üye olabilir." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "birthday", value: false, message: "Sitemize sadece 18 yaşından büyükler üye olabilir." }]);
            }
            return false;
        }
    };
    

    const goStep1 = async () => {
        const isPasswordValid = await ValidatePassword(password);
        const isPass2Valid = await ValidatePass2(confirmPassword, password);
        const isTCNoValid = await validateTCNo(tckn);
        const isUsernameValid = await CheckUsername(username);
        const isEmailValid = await ValidateEmail(email);
        const isBirthdayValid = await BirthdayCheck(birthday);
    
        if (
            isPasswordValid &&
            isPass2Valid &&
            isTCNoValid &&
            isUsernameValid &&
            isEmailValid &&
            isBirthdayValid
        ) {
            console.log("All validations passed");
            setActiveStep(1);
        } else {
            console.log("One or more validations failed");
        }
    };

    const goStep2 = async () => {
        const results = await Promise.all([
            ValidateSurName(surname),
            ValidateName(name),
            ValidateCountry(country),
            ValidateCity(city),
            ValidateAdress(adress)
        ]);
    
        // Tüm doğrulamalar true dönerse
        if (results.every(result => result === true)) {
            setActiveStep(2);
        }
    }


    useEffect(() => {
        setStepCheck(inputerror.some(obj => obj.value === false))
    }, [inputerror])

    const ValidateName = async (data) => {
        const tcObj = inputerror.find(item => item.id === "name");
        if (data.length >= 3) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "name" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "name", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "name" ? { ...item, value: false, message: "Lütfen geçerli bir isim girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "name", value: false, message: "Lütfen geçerli bir isim girin." }]);
            }
            return false;
        }
    }
    
    const ValidateSurName = async (data) => {
        const tcObj = inputerror.find(item => item.id === "surname");
        if (data.length >= 3) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "surname" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "surname", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "surname" ? { ...item, value: false, message: "Lütfen geçerli bir soyad girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "surname", value: false, message: "Lütfen geçerli bir soyad girin." }]);
            }
            return false;
        }
    }
    
    const ValidateCountry = async (data) => {
        const tcObj = inputerror.find(item => item.id === "country");
        if (data.length >= 3) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "country" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "country", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "country" ? { ...item, value: false, message: "Lütfen geçerli bir ülke girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "country", value: false, message: "Lütfen geçerli bir ülke girin." }]);
            }
            return false;
        }
    }
    
    const ValidateCity = async (data) => {
        const tcObj = inputerror.find(item => item.id === "city");
        if (data.length >= 3) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "city" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "city", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "city" ? { ...item, value: false, message: "Lütfen geçerli bir şehir girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "city", value: false, message: "Lütfen geçerli bir şehir girin." }]);
            }
            return false;
        }
    }
    
    const ValidateAdress = async (data) => {
        const tcObj = inputerror.find(item => item.id === "adress");
        if (data.length >= 3) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "adress" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "adress", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "adress" ? { ...item, value: false, message: "Lütfen geçerli bir adres girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "adress", value: false, message: "Lütfen geçerli bir adres girin." }]);
            }
            return false;
        }
    }
    const ValidatePhone = async (data) => {
        const tcObj = inputerror.find(item => item.id === "phone");
        if (data.length == 10) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "phone" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "phone", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "phone" ? { ...item, value: false, message: "Lütfen geçerli bir telefon girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "phone", value: false, message: "Lütfen geçerli bir telefon girin." }]);
            }
            return false;
        }
    }
    const ValidateCountryCode= async (data) => {
        const tcObj = inputerror.find(item => item.id === "ccode");
        if (data.length >= 1) {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "ccode" ? { ...item, value: true } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "ccode", value: true }]);
            }
            return true;
        } else {
            if (tcObj) {
                setInputError(prevState =>
                    prevState.map(item =>
                        item.id === "ccode" ? { ...item, value: false, message: "Lütfen geçerli bir ülke girin." } : item
                    )
                );
            } else {
                setInputError(prevState => [...prevState, { id: "ccode", value: false, message: "Lütfen geçerli bir ülke girin." }]);
            }
            return false;
        }
    }
    const goRegister = async()=>{
        const results = await Promise.all([
            ValidatePassword(password),
            ValidatePass2(confirmPassword, password),
            validateTCNo(tckn),
            ValidateSurName(surname),
            ValidateEmail(email),
            BirthdayCheck(birthday),
            CheckUsername(username),
            ValidateName(name),
            ValidateCountry(country),
            ValidateCity(city),
            ValidateAdress(adress),
            ValidatePhone(phone),
            ValidateCountryCode(countrycode)
    
        ]);
        if (results.every(result => result === true)) {
            axios.post("https://api.youbetshop.com/register", { username, email, tcno:tckn, pass:password, pass2:confirmPassword, name, surname, promo_code:promocode2, tel:phone, address:adress, city:city, country:country, user_type:1, birthday :birthday})
            .then(response => {
              if (response) {
                if (response.data.success === true) {
                    setActiveStep(3);
                } else {
                  setMessage(response.data.data.message || translatedata[selectedLanguage].registerdialog.registererror);
                  setOpen(true);
                  setStat("error");
                }
              } else {
                setMessage(translatedata[selectedLanguage].registerdialog.registererror);
                setOpen(true);
                setStat("error");
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              setMessage(translatedata[selectedLanguage].registerdialog.registererror);
              setOpen(true);
              setStat("error");
            });
        }else{
            setMessage(translatedata[selectedLanguage].registerdialog.registererror);
            setOpen(true);
            setStat("error");   
        }
    }
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
                            <ArrowBackIcon />
                            <Typography variant="h6">
                                {translatedata[selectedLanguage].transaction.home}
                            </Typography>
                        </IconButton>
                        <Typography variant="body1" style={{ marginRight: '10px' }}>
                            {translatedata[selectedLanguage].registerdialog.register}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Container>
                <Box my={4} sx={{ backgroundColor: "#ededed", borderRadius: "1rem", padding: "15px" }}>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={7} lg={7} sx={{ padding: isMobile?"20px":"50px" }}>
                            <Typography variant={"h3"}>{translatedata[selectedLanguage].registerdialog.register}</Typography>
                            <Stepper nonLinear activeStep={activeStep} sx={{ marginBottom: "50px", marginTop: "50px" }}>
                                {steps.map((label, index) => (
                                    <Step key={label} completed={completed[index]}>
                                        <StepButton color="inherit" onClick={handleStep(index)}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep == 0 &&
                                <>
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.email}</Typography>
                                    <TextField

                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.email}
                                        type="email"
                                        fullWidth
                                        value={email}
                                        onBlur={() => ValidateEmail(email)}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "mail")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "mail").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.username}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.username}
                                        fullWidth
                                        value={username}
                                        onBlur={() => CheckUsername(username)}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "username")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "username").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.pass}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.pass}
                                        type={showPassword ? 'text' : 'password'}
                                        fullWidth
                                        value={password}
                                        onBlur={() => ValidatePassword(password)}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "pass1")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "pass1").message}</Typography>
                                    }
                                    <Typography  >{translatedata[selectedLanguage].registerdialog.repass}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.repass}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        onBlur={() => ValidatePass2(confirmPassword, password)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle confirm password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "pass2")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "pass2").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.idno}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.idno}
                                        type="number"
                                        fullWidth
                                        value={tckn}
                                        onBlur={() => { validateTCNo(tckn) }}
                                        onChange={(e) => {
                                            // Only allow input if it's 11 characters or less
                                            if (e.target.value?.length <= 11) {
                                                setTckn(e.target.value);
                                            }
                                        }}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "tc")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "tc").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.birthday}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                                        <DatePicker
                                            label={translatedata[selectedLanguage].registerdialog.birthday}
                                            sx={{ width: "100%" }}
                                            value={birthday}
                                            onChange={(e) => setBirthday(e)}
                                            slotProps={{
                                                textField: {
                                                    helperText: 'GG.AA.YYYY',
                                                    onBlur: () => BirthdayCheck(birthday), // Attach the onBlur here
                                                },
                                            }}
                                            onClose={() => BirthdayCheck(birthday)} // Optional: Handle when the picker closes
                                        />
                                    </LocalizationProvider>
                                    {
                                        inputerror.find((item) => item.id == "birthday")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "birthday").message}</Typography>
                                    }

                                    <Grid container justifyContent={"end"} sx={{ marginTop: "15px" }}>
                                        <Button variant="contained" onClick={() => goStep1()}>{"İleri"}</Button>
                                    </Grid>
                                </>

                            }
                            {activeStep == 1 &&
                                <>
                                    <Grid container sx={{ marginTop: "50px" }}>
                                        <Grid item xs={6} md={6} sm={6} lg={6}>

                                            <Typography>{translatedata[selectedLanguage].registerdialog.name}</Typography>
                                            <TextField
                                                margin="dense"
                                                label={translatedata[selectedLanguage].registerdialog.name}
                                                type="name"
                                                fullWidth
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                onBlur={() => ValidateName(name)}
                                            />
                                        </Grid>  <Grid item xs={6} md={6} sm={6} lg={6}>
                                            <Typography>{translatedata[selectedLanguage].registerdialog.surname}</Typography>  <TextField
                                                margin="dense"
                                                label={translatedata[selectedLanguage].registerdialog.surname}
                                                type="surname"
                                                fullWidth
                                                value={surname}
                                                onChange={(e) => setSurname(e.target.value)}
                                                onBlur={() => ValidateSurName(surname)}
                                            />
                                        </Grid>
                                        {
                                            inputerror.find((item) => item.id == "name")?.value == false &&
                                            <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "name").message}</Typography>
                                        }

                                        {
                                            inputerror.find((item) => item.id == "surname")?.value == false &&
                                            <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "surname").message}</Typography>
                                        }

                                    </Grid>
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.country}</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Ülke</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Ülke"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            onBlur={() => ValidateCountry(country)}
                                        >
                                                   {countryall.map((item, index) => (
                                                <MenuItem value={item.country} key={index+item.country}>
                                                    {item.country} 
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                    {
                                        inputerror.find((item) => item.id == "country")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "country").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.city}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.city}
                                        type="city"
                                        fullWidth
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        onBlur={() => ValidateCity(city)}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "city")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "city").message}</Typography>
                                    }
                                    <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.adress}</Typography>
                                    <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.adress}
                                        type="adress"
                                        fullWidth
                                        value={adress}
                                        onChange={(e) => setAdress(e.target.value)}
                                        onBlur={() => ValidateAdress(adress)}
                                    />
                                    {
                                        inputerror.find((item) => item.id == "adress")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "adress").message}</Typography>
                                    }
                                    <Grid container justifyContent={"end"} sx={{ marginTop: "15px" }}>
                                        <Button variant="contained" onClick={() => goStep2()}>{"İleri"}</Button>
                                    </Grid>
                                </>}
                                {activeStep == 2 &&
                                <>                      
                                    <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                                        <Grid item xs={4.2} sm={4.2} md={2.2} lg={2.2}>
                                        <Typography>{translatedata[selectedLanguage].registerdialog.country}</Typography>
                                        <FormControl fullWidth sx={{marginTop:"5px"}}>
                                        <InputLabel id="demo-simple-select-label">Ülke</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Ülke"
                                            value={countrycode}
                                            onChange={(e) => setCountryCode(e.target.value)}
                                            onBlur={() => ValidateCountryCode(country)}
                                            renderValue={(selected) => "+"+selected} // Sadece ülke kodunu gösterir
                                        >
                                            {countryall.map((item) => (
                                                <MenuItem value={item.code} key={item.code+"1"}>
                                                    {item.country} ({"+" + item.code})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        </Grid>
                                        <Grid item xs={7.8} sm={7.8} md={9.8} lg={9.8}>
                                        <Typography >{translatedata[selectedLanguage].registerdialog.telephone}</Typography>
                                        <TextField
                                        margin="dense"
                                        label={translatedata[selectedLanguage].registerdialog.telephone}
                                        type="number"
                                        fullWidth
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onBlur={() => ValidatePhone(phone)}
                                    />
                                        </Grid>
                                    </Grid>
                                    
                                    {
                                        inputerror.find((item) => item.id == "ccode")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "ccode").message}</Typography>
                                    }
                                                                        
                                                                        {
                                        inputerror.find((item) => item.id == "phone")?.value == false &&
                                        <Typography sx={{ fontSize: "0.8rem", color: "#cf0000" }}>{inputerror.find((item) => item.id == "phone").message}</Typography>
                                    }
                                           <Typography sx={{ marginTop: "20px" }}>{translatedata[selectedLanguage].registerdialog.promocodetext}</Typography>
                                                <TextField
            margin="dense"
            label={translatedata[selectedLanguage].registerdialog.promocodetext}
            type="promo"
            fullWidth
            
            value={promocode2}
            onChange={(e) => setPromoCodes(e.target.value)}
          />
                                      <FormControlLabel sx={{ marginTop: "20px" }} control={<Checkbox defaultChecked />} label={translatedata[selectedLanguage].registerdialog.conftext} />
                                    <Grid container justifyContent={"center"} sx={{ marginTop: "25px"}}>
                                        <Button variant="contained" fullWidth onClick={() => goRegister()}>{"Kayıt Ol"}</Button>
                                    </Grid>
                                </>}
                               
                                {activeStep == 3 &&
                                <>       
                                                                    <Grid container spacing={2} sx={{ marginTop: "5px",width:"100%",textAlign:"center",justifyContent:"center"}}>
                                        <img src={ check} style={{maxWidth:"150px"}}></img>
                                    </Grid>               
                                    <Grid container spacing={2} sx={{ marginTop: "5px",width:"100%",textAlign:"center",justifyContent:"center"}}>
                                        <Typography variant={"h4"} sx={{textAlign:"center"}}>{translatedata[selectedLanguage].registerdialog.registersuccess}</Typography>
                                    </Grid>
                                    <Grid container justifyContent={"center"} sx={{ marginTop: "25px"}}>
                                        <Button variant="contained" fullWidth onClick={() => setLoginOpen(true)}>{"Giriş Yap"}</Button>
                                    </Grid>
                                </>}
                                
                        </Grid>

                        {!isMobile &&
                            <Grid
                                item
                                xs={12} sm={12} md={5} lg={5}
                                sx={{
                                    borderTopRightRadius: "1rem",
                                    borderBottomRightRadius: "1rem",
                                    backgroundImage: "url('./image/register.webp')",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                    height: "100vh",
                                }}
                            >
                            </Grid>}
                    </Grid>

                </Box>
            </Container>
            <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
        </>
    );
}

export default Register;
