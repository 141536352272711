import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsBx="https://boxy-svg.com"
      viewBox="0 0 355.023 90.753"
      height={"40px"}
    >
      <defs>
        <style bxFonts="Alexandria">
          @import
          url(https://fonts.googleapis.com/css2?family=Alexandria%3Aital%2Cwght%400%2C100..900&amp;display=swap);
        </style>
      </defs>
      <path
        fill="#ece4d4"
        d="M209.456 44.332l18.689 24.474 3.775 4.988c1.019 1.386 2.039 2.876 3.29 4.066l-11.966.017c-1.94.001-4.049.168-5.972-.073-1.668-1.673-3.059-3.747-4.506-5.618l-8.523-11.16c-1.219-1.608-2.446-3.651-3.918-5.025.923-.717 1.801-2.236 2.538-3.19l6.593-8.479zM200.312 32.311c2.324-2.465 4.289-5.334 6.311-8.048l5.992-7.815c1.382-1.826 2.99-4.66 4.844-5.964l17.734-.027-17.734 23.095-5.671 7.387c-.762.99-1.49 2.168-2.337 3.072-.808-.67-1.538-1.893-2.185-2.742l-4.348-5.672c-.837-1.089-1.65-2.303-2.606-3.286z"
      ></path>
      <path
        fill="#FF6C00"
        d="M122.762 10.393l35.445.064.015 14.311-35.451-.051-.009-14.324zM122.744 63.666l35.491-.033-.026 14.25-35.494.134.029-14.351z"
      ></path>
      <path
        fill="#FF6C00"
        d="M200.325 56.001l-16.872 21.794c-1.697.275-3.718.065-5.446.063l-12.491.033 20.5-26.58-63.294.022.014-14.36 63.235.152-20.434-26.665 17.933.134c1.794 1.73 3.299 4.125 4.817 6.123 3.941 5.186 7.762 10.672 12.025 15.594.956.983 1.769 2.197 2.606 3.286l4.348 5.672c.647.849 1.377 2.072 2.185 2.742l.005.321-6.593 8.479c-.737.954-1.615 2.473-2.538 3.19z"
      ></path>
      <text
        style={{ whiteSpace: "pre" }}
        x="26.256"
        y="87.329"
        fill="#FF6C00"
        fontFamily="Alexandria"
        fontSize="16"
        fontWeight="600"
        transform="matrix(5.20986 0 0 6.06904 -132.773 -451.362)"
      >
        TH
      </text>
      <text
        style={{ whiteSpace: "pre" }}
        x="26.256"
        y="87.329"
        fill="#FEFEFE"
        fontFamily="Alexandria"
        fontSize="16"
        fontWeight="600"
        transform="matrix(5.20986 0 0 5.98095 94.272 -443.047)"
      >
        CH
      </text>
    </svg>
  );
}

export default Icon;
