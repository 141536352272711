import React, { useEffect, useState, useRef } from 'react';
import poseidon from '../img/poseidon.png';
import axios from 'axios';
import { Button, Container, Typography, Box, Table, TableBody,Dialog, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Grid, AppBar, Toolbar, IconButton, Link, Badge, Alert, useTheme, Tooltip, Popover, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InvoiceIcon from '@mui/icons-material/Receipt'; // Import the invoice icon
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';
import ChatComponent from '../component/chat';
import Qusetion from '../media/question.gif';
const Poseidon = ({ recheck, balance, blockbalance, setBalance,messages,setChatOpen, setBlockBalance, newbalance, blockedbalance, poseidonfee, selectedLanguage,isLoggedIn }) => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [data, setData] = useState([]);
    const [fdata, setFData] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    const [nowbalance, setNowBalance] = useState(balance);
    const [nowblockbalance, setNowBlockBalance] = useState(blockbalance);
    const [winvalue, setWinvalue] = useState();
    const [coupon, setCoupon] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [stat, setStat] = useState(false);
    const [opentool, setOpenTool] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null); // State for Popover
    const theme = useTheme();
    const popoverRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tutorialid,setTutorialid]=useState(13);
    const [tutorialopen,setTutorialOpen]=useState(false);
    const navigate = useNavigate();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setNowBalance(newbalance);
        setNowBlockBalance(blockedbalance);
        setBalance(newbalance);
        setBlockBalance(blockedbalance);
    }, [newbalance, blockedbalance]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                handlePopoverClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popoverRef]);
    useEffect(() => {
        if (isLoggedIn) {
          console.log("User is logged in.");
          const tutorial = Cookies.get('tutorial');
          console.log("Tutorial cookie:", tutorial);
          if (tutorial !== "2") {
            setTutorialOpen(true);
            setTutorialid(13);
          }
        }
      }, [isLoggedIn]);
    useEffect(() => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios.get("https://api.youbetshop.com/api/feed/poseidon-feed", config)
            .then(response => {
                const events = response.data.data.map(event => ({
                    ...event,
                    openDate: new Date(event.openDate)
                }));
                events.sort((a, b) => a.openDate - b.openDate);
                setFData(events);
                setData(events);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [cookies.serviceToken]);

    const dateformat = (dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setHours(originalDate.getHours() - 3);
        return new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(originalDate);
    };

    useEffect(() => {
        if (coupon.length > 0) {
            const parseDatetime = (dtStr) => new Date(dtStr);

            const timeRange = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

            const filtered = fdata.filter((detailedMatch) => {
                const detailedMatchTime = parseDatetime(detailedMatch.openDate).getTime();
                return coupon.every((match) => {
                    const matchTime = parseDatetime(match.datetime).getTime();
                    const startTime = matchTime - timeRange;
                    const endTime = matchTime + timeRange;

                    if (detailedMatch.id === match.matchid) {
                        return true;
                    } else {
                        return !(startTime < detailedMatchTime && detailedMatchTime < endTime) && matchTime !== detailedMatchTime;
                    }
                });
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(fdata);
        }
    }, [coupon, fdata]);

    const addCoup = (matchid, matchname, datetime, marketid, marketname, oddid, oddname, price) => {
        setCoupon(prevCoupon => {
            const existingIndex = prevCoupon.findIndex(coup =>
                coup.matchid === matchid &&
                coup.marketid === marketid &&
                coup.oddid === oddid
            );
            if (existingIndex !== -1) {
                return prevCoupon.filter((_, index) => index !== existingIndex);
            }
            const matchIndex = prevCoupon.findIndex(coup => coup.matchid === matchid);
            if (matchIndex !== -1) {
                const newCoupon = [...prevCoupon];
                newCoupon[matchIndex] = { matchid, matchname, datetime, marketid, marketname, oddid, oddname, price };
                return newCoupon;
            }
            return [...prevCoupon, { matchid, matchname, datetime, marketid, marketname, oddid, oddname, price }];
        });
    };

    const isButtonActive = (oddid, matchid) => {
        return coupon.some(coup => coup.oddid === oddid && coup.matchid === matchid);
    };

    useEffect(() => {
        const total = coupon.reduce((acc, item) => acc * item?.price, 1);
        setTotalPrice(total.toFixed(2));
    }, [coupon]);

    const handleTooltipOpen = () => {
        setOpenTool(prev => !prev);
        if (!opentool) {
            setTimeout(handleTooltipClose, 5000);
        }
    };

    const handleTooltipClose = () => {
        setOpenTool(false);
    };
    const removeMatchFromCoupon = (matchid) => {
        setCoupon((prevCoupon) => prevCoupon.filter((item) => item.matchid !== matchid));
    };
    const sendCoupon = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const params = { descriptionar: coupon, mode: "Poseidon", type: "LAY", stake: winvalue };
        axios.post("https://api.youbetshop.com/api/coupon/createcoupon", params, config)
            .then(response => {
                if (response.data.data.success) {
                    setMessage(translatedata[selectedLanguage].notify.couponcreated);
                    setOpen(true);
                    setStat("success");
                    setWinvalue('');
                    handlePopoverClose();
                    setCoupon([]);
                } else {
                    setMessage(response.data.data.message);
                    setOpen(true);
                    setStat("error");
                }
                recheck();
            })
            .catch(error => {
                console.error('Error creating coupon:', error);
                setMessage(error.response.data.message);
                setOpen(true);
                setStat("error");
            });
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
    };

    useEffect(() => {
        if (winvalue > 0) {
            setBalance(formatNumber((nowbalance - (winvalue * (totalPrice - 1))).toFixed(2)));
            setBlockBalance(formatNumber((nowblockbalance + (winvalue * (totalPrice - 1))).toFixed(2)));
        } else {
            setBalance(formatNumber(nowbalance));
            setBlockBalance(formatNumber(nowblockbalance));
        }
    }, [winvalue, nowbalance, nowblockbalance, totalPrice]);

    const filterSearch = (e) => {
        const searchTerm = e.toLowerCase();
        if (!e) {
            setFData(data);
        } else {
            const filteredData = data.filter(item => item.name && item.name.toLowerCase().includes(searchTerm));
            setFData(filteredData);
        }
    };

    const handlePopoverOpen = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const opentutorial = (i)=>{
        setTutorialOpen(true);
        setTutorialid(i);
    }
    const openPopover = Boolean(anchorEl);

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => {
                        navigate("/");
                        setBalance(formatNumber(nowbalance));
                        setBlockBalance(formatNumber(nowblockbalance));
                    }}>
                        <ArrowBackIcon />
                        <Typography variant="h6">
                            {translatedata[selectedLanguage].poseidon.home}
                        </Typography>
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1 }} />
                    <Typography variant="body1" style={{ marginRight: '10px' }}>
                        {translatedata[selectedLanguage].poseidon.comission + ": %" + poseidonfee}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{borderRadius: "15px",width:"100%",paddingLeft:"20px",paddingRight:"20px",paddingTop:"10px",paddingBottom:"1px",marginBottom:"10px",marginTop:"10px",backgroundColor:"#ece4d4a6"}}>
            <ChatComponent messages={messages} setChatOpen={setChatOpen}/>
            </Container>
            <Container sx={{ marginBottom: "30px", backgroundColor: "#ece4d4", padding: "20px", borderRadius: "15px" }}>
                <Box
                    my={4}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '15px',
                    }}
                >
                    <Box
                        ml={2}
                        textAlign="left"
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: 'flex-start',
                        }}
                    >
                        <img
                            src={poseidon}
                            alt="Poseidon"
                            style={{ width: '150px', height: '150px', borderRadius: '10px' }}
                        />
                        <Box
                            ml={2}
                            textAlign="left"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography variant="h4" display="flex" sx={{ alignItems: 'center' }}>
                                {translatedata[selectedLanguage].poseidon.Poseidon}
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            {translatedata[selectedLanguage].poseidon.poseidondesc + poseidonfee + translatedata[selectedLanguage].poseidon.poseidondesc2}
                                        </Typography>
                                    }
                                    arrow
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipClose}
                                    open={opentool}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <IconButton onClick={handleTooltipOpen}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <Typography> {translatedata[selectedLanguage].poseidon.poseidonbook}</Typography>
                            {isMobile &&
                    <Box                         sx={{
                        justifyContent: "end",display:"flex"
                    }}>
                            <img src={Qusetion} style={{maxWidth:"60px",display:"flex"}} onClick={()=>opentutorial(13)}/>
                            <img src={Qusetion} style={{maxWidth:"60px",display:"flex"}} onClick={()=>opentutorial(14)}/>
                            </Box>
                            }
                        </Box>
                        {!isMobile &&
                            <img src={Qusetion} style={{maxWidth:"100px",display:"flex"}} onClick={()=>opentutorial(13)}/>
                            }
                    </Box>
                    <Box
                        textAlign="left"
                        sx={{
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: { xs: 'flex-start', md: 'flex-end' },
                            mt: { xs: 2, md: 0 },
                        }}
                    > 
                                                {!isMobile &&
                                                <Box                         sx={{
                                                    justifyContent: "end",display:"flex"
                                                }}>
                            <img src={Qusetion} style={{maxWidth:"50px",display:"flex"}} onClick={()=>opentutorial(14)}/>
                            </Box>
                            }
                        <Typography sx={{ fontSize: '0.7rem' }}>
                            {translatedata[selectedLanguage].poseidon.poseidonbookdesc}
                        </Typography>

                        <Box
                            ml={2}
                            textAlign="left"
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: "0px" }}
                        >
         
                            <TextField label={translatedata[selectedLanguage].poseidon.matchsearch} onChange={(e) => filterSearch(e.target.value)} sx={{ width: '100%' }} />
                        </Box>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    {isMobile ?
                        filteredData?.map((item, index) => (
                            <Paper key={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                    transition: 'height 0.3s ease',
                                    borderTop: "solid 1px #d79f09"
                                }}>
                                <Grid container sx={{ padding: "15px" }}>
                                    <Grid item xs={6} md={6} sm={6} lg={6}>
                                        <Typography>{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} sx={{ textAlign: 'right' }}>
                                        <Typography>{dateformat(item.openDate)}</Typography>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div style={{ overflowX: "auto" }}>
                                                <Grid container sx={{ whiteSpace: "nowrap", minWidth: "475px" }}>
                                                    <Grid item sx={{ width: "30%" }}>
                                                        <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.matchresult} </Typography>
                                                    </Grid>
                                                    <Grid item sx={{ width: "30%" }}>
                                                        <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.doublechange}</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ width: "20%" }}>
                                                        <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.underover}</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ width: "20%" }}>
                                                        <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.goals}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{ whiteSpace: "nowrap", minWidth: "475px" }}>
                                                    <Grid item sx={{ width: "30%" }}>
                                                        {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                            <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                {market.runners[0].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[2].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[1].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                            </Box>
                                                        ))}
                                                    </Grid>
                                                    <Grid item sx={{ width: "30%" }}>
                                                        {item.marketCatalogues.map((market) => market.marketName === "Çifte Şans" && (
                                                            <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                {market.runners[0].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["1 veya X"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[1].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["X veya 2"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[2].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["1 veya 2"]}<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                            </Box>
                                                        ))}
                                                    </Grid>
                                                    <Grid item sx={{ width: "20%" }}>
                                                        {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                            <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                {market.runners[0].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Alt 2.5"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[1].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Üst 2.5"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                            </Box>
                                                        ))}
                                                    </Grid>
                                                    <Grid item sx={{ width: "20%" }}>
                                                        {item.marketCatalogues.map((market) => market.marketName === "Karşılıklı Gol" && (
                                                            <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                {market.runners[0].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Var"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                                {market.runners[1].ex.availableToLay[0]?.price > 1 ?
                                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                                        {translatedata[selectedLanguage].odd["Yok"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                                    </Button> : <Button style={{ padding: "0px", minWidth: "50px" }} >
                                                                    </Button>}
                                                            </Box>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Paper>
                        ))
                        :
                        <Table >
                            <TableHead sx={{ backgroundColor: "#d5d5d5" }} >
                                <TableRow  >
                                    <TableCell>{translatedata[selectedLanguage].poseidon.matchname}</TableCell>
                                    <TableCell>{translatedata[selectedLanguage].poseidon.matchdate}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.matchresult}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.doublechange}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.goals}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.underover}</TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData?.map((item, index) => (
                                    <TableRow key={item.id}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                            transition: 'height 0.3s ease',
                                        }}
                                    >
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{dateformat(item.openDate)}</TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                           {market.runners[0].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                           {market.runners[2].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                    {market.runners[1].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                </Box>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Çifte Şans" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                       {market.runners[0].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["1 veya X"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                       {market.runners[2].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[2].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["1 veya 2"]}<br />{market.runners[2].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                    {market.runners[1].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["X veya 2"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                </Box>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Karşılıklı Gol" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                    {market.runners[0].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0].price) }}>
                                                    {translatedata[selectedLanguage].odd["Var"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                    {market.runners[1].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Yok"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                </Box>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ padding: "0px" }}>
                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                            {market.runners[0].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[0].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Alt 2.5"]}<br />{market.runners[0].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                    {market.runners[1].ex.availableToLay[0]?.price>1?
                                                    <Button variant={isButtonActive(market.runners[1].selectionId, item.id) ? 'contained' : 'outlined'} style={{ padding: "0px", minWidth: "50px" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1].ex.availableToLay[0]?.price) }}>
                                                    {translatedata[selectedLanguage].odd["Üst 2.5"]}<br />{market.runners[1].ex.availableToLay[0]?.price}
                                                    </Button>:<Button  style={{ padding: "0px", minWidth: "50px" }} >
                                                    </Button>}
                                                </Box>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
                </TableContainer>

                <Box mt={4} textAlign="center">
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6"> {translatedata[selectedLanguage].poseidon.totalodd}:</Typography>
                            <Typography variant="h4" color="primary">{totalPrice}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6">{translatedata[selectedLanguage].poseidon.winamount}:</Typography>
                            <TextField type="number" value={winvalue} onChange={(e) => setWinvalue(e.target.value)} variant="outlined" />
                            <Typography variant="body2" color="textSecondary" onClick={() => setWinvalue(((balance / (totalPrice - 1)).toFixed(2)))}>{translatedata[selectedLanguage].poseidon.maxbuys}: <span style={{ textDecoration: "underline", color: "#d79f09", cursor: "pointer" }}>{totalPrice > 1 && (Number(nowbalance) / (totalPrice - 1)).toFixed(2)}</span></Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="h6">{translatedata[selectedLanguage].poseidon.blokamount}:</Typography>
                            <Typography variant="h4" color="secondary">{winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={4} textAlign="center" sx={{ marginBottom: "20px" }}>
                    <Button variant="contained" color="primary" onClick={sendCoupon}>{translatedata[selectedLanguage].poseidon.sendcoupon}</Button>
                </Box>
            </Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={stat}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                    <Button sx={{ color: "#ece4d4", textDecoration: "underline" }} onClick={() => navigate("/my-bets")}>
                        {translatedata[selectedLanguage].poseidon.mybets}
                    </Button>
                </Alert>
            </Snackbar>
            <Dialog
                open={openPopover}
                onClose={handlePopoverClose}
                disableScrollLock={true}
                PaperProps={{
                    sx: {
                        width: { xs: "100%", sm: "25%" },
                        position: 'fixed',
                        bottom: "5%"// Badge'in hemen üstünde olmasını sağlamak için margin ekleyin
                    }
                }}
            >
                <Grid container sx={{ padding: "10px" }}>
                    {coupon.length > 0 ? (
                        <>
                            {coupon.map((item, index) => (
                                <Grid container key={index} sx={{ fontSize: "0.8rem", padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
                                    <Grid item xs={5} sm={5}>
                                        {item.matchname}
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                        {translatedata[selectedLanguage].market[item.marketname]}
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        {translatedata[selectedLanguage].odd[item.oddname]}
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        {item.price}
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        <IconButton onClick={() => removeMatchFromCoupon(item.matchid)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            {/* Ekstra Grid Bileşeni */}
                            <Grid container sx={{ fontSize: "0.8rem", padding: "5px", alignItems: "center" }}>
                                <Grid item xs={4.5} sm={4.5}>
                                    {translatedata[selectedLanguage].poseidon.totalblokamount}:
                                </Grid>
                                <Grid item xs={3.5} sm={3.5}>
                                    <Typography sx={{ color: "#d79f09", fontWeight: "800" }}>  {winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    {translatedata[selectedLanguage].poseidon.totalodd + ":"}
                                </Grid>
                                <Grid item xs={1} sm={1}>
                                    <Typography sx={{ color: "#d79f09", fontWeight: "800" }}> {totalPrice}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        type="number"
                                        value={winvalue}
                                        label={translatedata[selectedLanguage].poseidon.winamount}
                                        sx={{
                                            width: "100%",
                                            marginTop: "5px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                top: "2px",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "9px",
                                            },
                                        }}
                                        onChange={(e) => setWinvalue(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Button variant="contained" color="primary" sx={{ width: "100%", marginTop: "12px", marginLeft: "5px", padding: "4px 16px" }} onClick={sendCoupon}>{translatedata[selectedLanguage].poseidon.sendcoupon}</Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid container sx={{ padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
                            <Typography sx={{ textAlign: "center", color: "#d79f09" }}>
                                {translatedata[selectedLanguage].poseidon.nothingcoup}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Dialog>
            <Fab
                color="primary"
                aria-label="invoice"
                onClick={handlePopoverOpen}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Badge
                    badgeContent={coupon.length}
                    color="secondary"
                    sx={{
                        '& .MuiBadge-badge': {
                            position: 'absolute',
                            right: -6,
                            top: -6,
                            backgroundColor: '#000',
                            color: '#fff',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.75rem',
                        }
                    }}
                >
                    <InvoiceIcon />
                </Badge>
            </Fab>


           

            <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
        </>
    );
};

export default Poseidon;
