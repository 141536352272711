import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert, IconButton, InputAdornment, Link, CircularProgress,ButtonGroup,Box,useTheme,useMediaQuery } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import translatedata from '../translate.json';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
const LoginDialog = ({ open, onClose, setIsLoggedIn, setUsername, setBalance,setNotify,selectedLanguage }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleLogin = () => {
    axios.post("https://api.youbetshop.com/login", { email: email, pass: password,user_type:1 })
      .then(response => {
        if (response.data.success) {
          setMessage(translatedata[selectedLanguage].logindilaog.loginsuccess);
          setIsLoggedIn(true);
          setOpenSnack(true);
          setCookie('serviceToken', response.data.data.token);
          setCookie('email', response.data.data.email);
          setBalance(response.data.data.balance);
          setUsername(response.data.data.email);
          setNotify(response.data.data.ticket_admin_res)
          setStat("success");
          onClose();
        } else {
          setMessage(response.data.data.message);
          setOpenSnack(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage(translatedata[selectedLanguage].logindilaog.loginerror);
        setOpenSnack(true);
        setStat("error");
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSubmit = () => {
    setLoading(true);
    axios.post("https://api.youbetshop.com/forgotpass", { email: forgotEmail })
      .then(response => {
        if (response.data.success) {
          setMessage(translatedata[selectedLanguage].logindilaog.passmailsuccess);
          setStat("success");
        } else {
          setMessage(translatedata[selectedLanguage].logindilaog.passmailerror);
          setStat("error");
        }
        setOpenSnack(true);
        setForgotPasswordOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error sending forgot password email:', error);
        setMessage(translatedata[selectedLanguage].logindilaog.passmailerror);
        setOpenSnack(true);
        setStat("error");
        setForgotPasswordOpen(false);
        setLoading(false);
      });
  };
  const handleTelegramClick = () => {
    window.open("https://t.me/youbetshop", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/447437586218", "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@youbetshop.com";
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
      <Box sx={{width:"100%",backgroundColor:"#d79f09"}}>  <DialogTitle> {translatedata[selectedLanguage].logindilaog.loginin}   </DialogTitle></Box>
        <DialogContent>
       
          <TextField
            autoFocus
            margin="dense"
            label={translatedata[selectedLanguage].logindilaog.email}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
          />
      
          <TextField
            margin="dense"
            label={translatedata[selectedLanguage].logindilaog.pass}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPasswordOpen}
            style={{ display: 'block', marginTop: 8 }}
          >
           {translatedata[selectedLanguage].logindilaog.passresset}
          </Link>

        </DialogContent>
        <DialogActions>
        <Box sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
        <Box >

</Box>
<Box>
          <Button onClick={onClose} color="primary" variant={"outlined"} sx={{marginRight:"2px"}}> {translatedata[selectedLanguage].logindilaog.cancel}</Button>
          <Button onClick={handleLogin} color="primary" variant={"contained"}> {translatedata[selectedLanguage].logindilaog.loginin}</Button>
          </Box>
          </Box>
        </DialogActions>
        <Box sx={{width:"100%",backgroundColor:"#d79f09",padding:"5px"}}>
        <ButtonGroup variant="text"  aria-label="Basic button group">
        <Button sx={{ color: "#000" }} onClick={handleTelegramClick}>
        <TelegramIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleWhatsAppClick}>
        <WhatsAppIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleEmailClick}>
        <EmailIcon />
      </Button>
</ButtonGroup>
</Box>
      </Dialog>

      <Dialog open={forgotPasswordOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle> {translatedata[selectedLanguage].logindilaog.passresset}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="E-posta"
            type="email"
            fullWidth
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose} color="primary">{translatedata[selectedLanguage].logindilaog.cancel}</Button>
          <Button onClick={handleForgotPasswordSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : translatedata[selectedLanguage].logindilaog.send}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginDialog;
